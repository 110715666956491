export const environment = {
  production: true,
  gdaxURL: 'wss://ws-feed.gdax.com',
  hmr: false,
  apiUrl: 'api/v1/',
  //apiUrl: 'http://localhost:3001/api/v1/',
  //apiUrl: 'http://52.64.218.207:3001/api/v1/',
  //baseUrl: 'http://52.64.218.207/',
  baseUrl: 'https://1superfund.com.au/',
  recaptchaSiteKey: "6LcSkEIiAAAAAFG_iGFzQD8MOa2S9sYmdAQ-JoRR",
  projectName: "1SMSF"
}
