<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>SPECIAL OFFER</h1>
            <p>Apply now for special offer</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">

                        <h3 style="color: red;font-weight: bold;"><i class="bx bxs-badge-check"></i> SPECIAL OFFER -
                            LIMITED TIME ONLY</h3>
                        <h3 class="text-color">
                            <li><i class="bx bxs-badge-check"></i> SMSF SETUP - From $ 349.00</li>
                        </h3>

                        <p>We can setup the smsf for you from very low as per our current special offer. You should
                            obtain your own research and if required should obtain independent financial and taxation
                            advice from a licensed financial planner about whether establishing an SMSF is appropriate
                            to your circumstances. We only setup the SMSF as per your instruction.</p>
                        <p>Free SMSF setup is done through off the shelf trust deed. </p>
                        <p>In case of those SMSF has complex setup, corporate trustee, custom trust deed, require
                            customised personal service we may quote you a nominal price before we proceeding with
                            the work.</p>
                        <p>Your establishment pack will include </p>
                        <ul>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Full Self-managed trust deed</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Trustee appointment documentation
                            </li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Member appointment documentation
                            </li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF establishment resolution and
                                minutes</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> ATO compliant trustee declaration
                                form</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Application to be a regulated SMSF
                                with ATO</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Application for director
                                identification Number</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company constitution
                            </li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company consents</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company register of
                                members</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company register of
                                directors</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company register of
                                secretaries</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company register of
                                public officers</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company share
                                certificates</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company Registered
                                office declarations</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company establishment
                                resolution and minutes</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company ASIC form 201
                                details</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF trustee company certificate of
                                registrations</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF running manual</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> SMSF knowledge resources</li>
                        </ul>
                        <h3 class="text-color">
                            <li><i class="bx bxs-badge-check"></i> YEARLY COMPLIANCE –Setup by <span
                                    class="logo-one-digit">1</span> superfund -From $1,099</li>
                        </h3>
                        <p><span class="logo-one-digit">1</span> superfund offers a very low fees for the first year of
                            the SMSF established by <span class="logo-one-digit">1</span> superfund.
                        </p>
                        <p style="color: red;">You are not bound to use our restrictive investment platform or buy other
                            financial products
                            from us which some provider make you do and obtains commission from , These amount to huge
                            direct and indirect savings !</p>
                        <p>The above fees include: SMSF Accounting, Financial Statement Preparation, Tax return lodgment
                            and including SMSF Audit </p>
                        <h3 class="text-color">
                            <li><i class="bx bxs-badge-check"></i> YEARLY COMPLIANCE – Transferring from another
                                provider / accountant - From $1,099</li>
                        </h3>
                        <p><span class="logo-one-digit">1</span> superfund offer includes attending to the following
                            annual compliance obligations for your
                            SMSF this includes SMSF Accounting, Financial Statement Preparation, Tax return lodgment and
                            including SMSF Audit</p>
                        <p style="color: red;">You are not bound to use our restrictive investment platform or buy
                            other financial products
                            from us which some provider make you do and obtains commission from, These amount to huge
                            direct
                            and indirect savings!</p>
                        <h3 style="color: red;">
                            <li><i class="bx bxs-badge-check"></i> FIRSTLY</li>
                        </h3>
                        <p> We will communicate with your old accountant and get all the past information , While you
                            sit
                            and relax !</p>
                        <p>Notify ATO that your SMSF is now our client and if anything needed to contact our office ,
                            taking pressure of you , while you execute the important job of running your SMSF.</p>
                        <h3 style="color: red;">
                            <li><i class="bx bxs-badge-check"></i> SECONDLY</li>
                        </h3>
                        <ul>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Preparation of an annual Balance
                                Sheet</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Preparation of an annual profit &
                                loss statement</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Preparation of annual member
                                statements</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Preparation of general ledger</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Preparation of notes to the
                                accounts</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Preparation of annual trustee
                                declarations</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Preparation and lodgment of an
                                annual Income tax return through ATO registered fax
                                agent</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Arranging independent annual audit
                                through ASIC registered SMSF auditor</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Document copy storage in our vault
                                while you keep the original</li>
                            <li class="normal-li"><i class='bx bx-check-double'></i> Full professional indemnity
                                insurance for liability arising from our negligence</li>
                        </ul>
                        <h3 class="text-color">
                            <li><i class="bx bxs-badge-check"></i> OTHER FEES </li>
                        </h3>
                        <p>When setting up a SMSF it is important to understand that additional fees may apply that must
                            be
                            carefully considered prior to making a decision to setup a SMSF including but not limited to
                            an
                            ATO supervisory levy, company trustee annual ASIC fees, bank charges, yearly accounting &
                            audit
                            fees, investment fees and tax expenses on any profit made in the SMSF. The fees varies from
                            provider to provider and can increase in future based on increase in wages, inflation and
                            average market rate by competitors in future.</p>
                        <p>For other <span class="logo-one-digit">1</span> superfund current fees – please refer Fees -
                            <a routerLink="/fees" class="text-color">Click here</a>
                        </p>




                        <p>Find out more about <a routerLink="/setup-smsf-process" style="color: #13c4a1;">SMSF Setup
                                Process</a>.</p>
                        <h2>SET UP YOUR SMSF LETS GO FOR IT!</h2>
                        <a routerLink="/apply-online" class="default-btn"><i class="bx bxs-hot"></i> Setup
                            SMSF<span></span></a> &nbsp;
                        <a routerLink="/appoint-us" class="optional-btn"><i class="bx bxs-hot"></i> Transfer
                            SMSF<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>