import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LogInComponent } from './components/pages/log-in/log-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ChattingComponent } from './components/common/chatting/chatting.component';
import { HttpClientModule } from '@angular/common/http';
import { AppointUsComponent } from './components/pages/appoint-us/appoint-us.component';
import { WhyUsComponent } from './components/pages/why-us/why-us.component';
import { FeesComponent } from './components/pages/fees/fees.component';
import { SetupNewSmsfComponent } from './components/pages/setup-new-smsf/setup-new-smsf.component';
import { SetupSmsfProcessComponent } from './components/pages/setup-smsf-process/setup-smsf-process.component';
import { SmsfSpecialOfferComponent } from './components/pages/smsf-special-offer/smsf-special-offer.component';
import { TakeOnProcessComponent } from './components/pages/take-on-process/take-on-process.component';
import { InvestmentsComponent } from './components/pages/investments/investments.component';
import { ContributionsRolloverComponent } from './components/pages/contributions-rollover/contributions-rollover.component';
import { ApplyOnlineComponent } from './components/pages/apply-online/apply-online.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SmsfRisksComponent } from './components/pages/smsf-risks/smsf-risks.component';
import { ResourcesTaxComponent } from './components/pages/resources-tax/resources-tax.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { OfferPopupComponent } from './components/popup/offer-popup/offer-popup.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { CovalentFileModule } from '@covalent/core/file';
import {MatPaginatorModule} from '@angular/material/paginator';

import { TrusteeDetailsPopupComponent } from './components/popup/trustee-details-popup/trustee-details-popup.component';
import { ErrorPopupComponent } from './components/pages/apply-online/error-popup/error-popup.component';
import { CompanyDetailsPopupComponent } from './components/popup/company-details-popup/company-details-popup.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    FooterComponent,
    AboutComponent,
    PricingComponent,
    TeamComponent,
    ContactComponent,
    FaqComponent,
    ErrorComponent,
    LogInComponent,
    SignUpComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    ChattingComponent,
    AppointUsComponent,
    WhyUsComponent,
    FeesComponent,
    SetupNewSmsfComponent,
    SetupSmsfProcessComponent,
    SmsfSpecialOfferComponent,
    TakeOnProcessComponent,
    InvestmentsComponent,
    ContributionsRolloverComponent,
    ApplyOnlineComponent,
    SmsfRisksComponent,
    ResourcesTaxComponent,
    OfferPopupComponent,
    TrusteeDetailsPopupComponent,
    ErrorPopupComponent,
    CompanyDetailsPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgImageSliderModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    CovalentFileModule,
    NgxCaptchaModule,
    RouterModule,
    MatPaginatorModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [
    OfferPopupComponent,
    TrusteeDetailsPopupComponent,
    ErrorPopupComponent,
    CompanyDetailsPopupComponent
  ]
})
export class AppModule { }
