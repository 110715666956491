import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contributions-rollover',
  templateUrl: './contributions-rollover.component.html',
  styleUrls: ['./contributions-rollover.component.scss']
})
export class ContributionsRolloverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
