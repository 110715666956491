<mat-toolbar style="background: none; margin-top: -20px;">
    <h2 style="text-align: center; font-weight: bolder; color: #F56928;" class="heading">Our
        Special Offer</h2>
    <span class="fill-remaining-space"></span>
    <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div class="main-container">
    <div class="container-box" style="border-top-left-radius: 20px; border-bottom-left-radius: 20px;">
        <div class="pop_header" style="border-top-left-radius: 20px;">
            SMSF SET-UP
        </div>
        <div class="text1">
            <h2>$349.<sup>00</sup></h2>
        </div>
        <div class="text2">
            <h2>Decided To Setup An SMSF? Your Super Your Way! We Can Assist,Better Still You Are NOT Bound
                To Use Any Specific Broker Account Or Buy Other Financial Product From Us!</h2>

        </div>
        <div class="btn">
            <button (click)="openFees()" style="border-radius: 10px;">from</button>
        </div>

    </div>
    <!--  -->
    <div class="container-box">
        <div class="pop_header">
            SMSF AUDIT
        </div>
        <div class="text1">
            <h2>$399.<sup>00</sup></h2>
        </div>
        <div class="text2">
            <h2>Trustees must appoint an approved SMSF auditor to audit your fund each year. our ASIC
                registered SMSF auditor can help you complete the audit.</h2>

        </div>
        <div class="btn">
            <button (click)="openFees()" style="border-radius: 10px;">from</button>
        </div>

    </div>
    <!--  -->
    <div class="container-box" style="border-top-right-radius: 20px; border-bottom-right-radius: 20px;">
        <div class="pop_header" style="border-top-right-radius: 20px;">
            FULL SMSF FINANCIAL+TAX+AUDIT
        </div>
        <div class="text1">
            <h2>$1,099.<sup>00</sup></h2>
        </div>
        <div class="text2">
            <h2>to enable trustees,get one stop service we can provide full financial,tax return and arrange
                audit through independent external auditor.</h2>

        </div>
        <div class="btn">
            <button (click)="openFees()" style="border-radius: 10px;">from</button>
        </div>
    </div>
</div>