<mat-toolbar class="mat-primary m-0">
    Details
    <span class="fill-remaining-space"></span>
    <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<form mat-dialog-content id="contactForm">

    <div class="row">
        <div class="col-sm-6">
            <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <mat-select matInput [(ngModel)]="formModel.title" [ngModelOptions]="{standalone: true}"
                    placeholder="Choose title" required>
                    <mat-option *ngFor="let element of _constantService.TITLE_LIST" [value]="element.id">
                        {{element.value}}</mat-option>
                </mat-select>
                <mat-error>Title is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.firstName" [ngModelOptions]="{standalone: true}"
                    placeholder="Enter first name" required>
                <mat-error>First Name is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Middle Name</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.middleName" [ngModelOptions]="{standalone: true}"
                    placeholder="Enter middle name">
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Surname</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.surname" [ngModelOptions]="{standalone: true}"
                    placeholder="Enter surname">
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Tax File Number</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.taxFileNumber" [ngModelOptions]="{standalone: true}"
                    placeholder="Enter tax file number" required>
                <mat-error>Tax File Number is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field appearance="outline">
                <mat-label>Date Of Birth</mat-label>
                <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker" readonly
                    [(ngModel)]="formModel.dateOfBirth" [ngModelOptions]="{standalone: true}">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <h5>Residential Address</h5>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.address.residentialAddress.address"
                    [ngModelOptions]="{standalone: true}" placeholder="Enter address" required>
                <mat-error>Address is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Suburb</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.address.residentialAddress.suburb"
                    [ngModelOptions]="{standalone: true}" placeholder="Enter suburb" required>
                <mat-error>Suburb is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <mat-select matInput [(ngModel)]="formModel.address.residentialAddress.state"
                    [ngModelOptions]="{standalone: true}" placeholder="Choose state" required>
                    <mat-option *ngFor="let element of _constantService.STATE_LIST" [value]="element.id">
                        {{element.value}}</mat-option>
                </mat-select>
                <mat-error>State is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Postcode</mat-label>
                <input matInput type="number" [(ngModel)]="formModel.address.residentialAddress.postCode"
                    [ngModelOptions]="{standalone: true}" placeholder="Enter postcode" required>
                <mat-error>Postcode is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <h5>Postal Address</h5>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.address.postalAddress.address"
                    [ngModelOptions]="{standalone: true}" placeholder="Enter address" required>
                <mat-error>Address is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Suburb</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.address.postalAddress.suburb"
                    [ngModelOptions]="{standalone: true}" placeholder="Enter suburb" required>
                <mat-error>Suburb is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <mat-select matInput [(ngModel)]="formModel.address.postalAddress.state"
                    [ngModelOptions]="{standalone: true}" placeholder="Choose state" required>
                    <mat-option *ngFor="let element of _constantService.STATE_LIST" [value]="element.id">
                        {{element.value}}</mat-option>
                </mat-select>
                <mat-error>State is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Postcode</mat-label>
                <input matInput type="number" [(ngModel)]="formModel.address.postalAddress.postCode"
                    [ngModelOptions]="{standalone: true}" placeholder="Enter postcode" required>
                <mat-error>Postcode is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.email" [ngModelOptions]="{standalone: true}"
                    [email]="true" #email="ngModel" placeholder="Enter email" required>
                <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                    <span *ngIf="email.errors.required">Email is required.</span>
                    <span *ngIf="email.errors.email">Enter a valid email address.</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput type="text" [(ngModel)]="formModel.mobileNumber" [ngModelOptions]="{standalone: true}"
                    (keypress)="keyPress($event)" minlength=10 maxlength=10 placeholder="Enter phone number" required>
                <mat-error>Phone Number is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Country of Citizenship</mat-label>
                <mat-select matInput [(ngModel)]="formModel.countryOfCitizenship" [ngModelOptions]="{standalone: true}"
                    placeholder="Choose Country of Citizenship">
                    <mat-option *ngFor="let element of _constantService.CITIZENSHIP_LIST" [value]="element.id">
                        {{element.value}}</mat-option>
                </mat-select>
                <mat-error>Country of Citizenship is required</mat-error>
            </mat-form-field>
        </div>
        <!-- <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Job Category</mat-label>
                <mat-select matInput [(ngModel)]="formModel.jobCategory" [ngModelOptions]="{standalone: true}"
                    placeholder="Choose Job Category" required>
                    <mat-option *ngFor="let element of _constantService.JOB_CATEGORY_LIST" [value]="element.id">
                        {{element.value}}</mat-option>
                </mat-select>
                <mat-error>Job Category is required</mat-error>
            </mat-form-field>
        </div> -->
        <!-- <div class="col-lg-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Job Type</mat-label>
                <mat-select matInput [(ngModel)]="formModel.jobType" [ngModelOptions]="{standalone: true}"
                    placeholder="Choose Job Type" required>
                    <mat-option *ngFor="let element of _constantService.STATE_LIST" [value]="element.id">
                        {{element.value}}</mat-option>
                </mat-select>
                <mat-error>Job Type is required</mat-error>
            </mat-form-field>
        </div> -->
        <div class="col-lg-6">
            <mat-form-field appearance="outline">
                <mat-label> Is Trustee an Undischarged Bankrupt?</mat-label>
                <mat-select [(ngModel)]="formModel.isTrusteeAnUndischargedBankrupt"
                    [ngModelOptions]="{standalone: true}" required>
                    <mat-option value="no">No</mat-option>
                    <mat-option value="yes">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field appearance="outline">
                <mat-label> Has Trustee been convicted of Dishonest Conduct?</mat-label>
                <mat-select [(ngModel)]="formModel.hasTrusteeBeenConvictedOfDishonestConduct"
                    [ngModelOptions]="{standalone: true}" required>
                    <mat-option value="no">No</mat-option>
                    <mat-option value="yes">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field appearance="outline">
                <mat-label>Has the Trustee Received a Civil Penalty Order?</mat-label>
                <mat-select [(ngModel)]="formModel.hasTheTrusteeReceivedACivilPenaltyOrder"
                    [ngModelOptions]="{standalone: true}" required>
                    <mat-option value="no">No</mat-option>
                    <mat-option value="yes">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field appearance="outline">
                <mat-label>Has Trustee been disqualified by the ATO or APRA?</mat-label>
                <mat-select [(ngModel)]="formModel.hasTrusteeBeenDisqualifiedByTheATOOrAPRA"
                    [ngModelOptions]="{standalone: true}" required>
                    <mat-option value="no">No</mat-option>
                    <mat-option value="yes">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field appearance="outline">
                <mat-label>Is the Trustee a Resident of Australia?</mat-label>
                <mat-select [(ngModel)]="formModel.isTheTrusteeAResidentOfAustralia"
                    [ngModelOptions]="{standalone: true}" required>
                    <mat-option value="no">No</mat-option>
                    <mat-option value="yes">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>

<mat-dialog-actions align="center">
    <button type="button" class="default-btn" [disabled]="isValid()" (click)="
                    submit()"><i class='bx bxs-paper-plane'></i> Submit</button>
</mat-dialog-actions>