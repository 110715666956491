import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(
        private _apiService: ApiService
    ) { }

    getDataList() {
        return this._apiService.get('navigation/list');
    }

    getDataByUrl(subNavigationUrl: any) {
        return this._apiService.get(`sub-navigation/getByUrl?url=${subNavigationUrl}`);
    }

    getSubNavigationListByUrl(navigationUrl: any) {
        return this._apiService.get(`navigation/getByNavigationUrl?url=${navigationUrl}`);
    }

}
