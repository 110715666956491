import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.css']
})
export class ErrorPopupComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<ErrorPopupComponent>
  ) {
    matDialogRef.disableClose = true;
  }

  ngOnInit() {
  }

}
