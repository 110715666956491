<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <p>The SMSF Story</p>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">How we are Founded</span>
                    <h1>Take your superfund to the next level</h1>
                    <p><span class="logo-one-digit">1</span> superfund is a no surprise fixed price SMSF accounting
                        service provider. We do not restrict you
                        on the investment choice you make, neither you must choose investment options from provider, nor
                        we do collect any commission from the investment you make. This makes <span
                            class="logo-one-digit">1</span> superfund different from
                        others. </p>
                    <p>We believe your super should be your way! We are here to provide full year end accounting, tax
                        and audit services to ensure regulatory compliance. You can access our free smsf resources any
                        time.</p>
                    <p><span class="logo-one-digit">1</span> superfund believes in building relationships with our
                        clients, and we view these relationships
                        as partnerships. Our service is founded on the commitment, dedication, and professionalism of
                        our people. We strive to service our clients by adhering to high standard of excellence. We are
                        your source for SMSF tax and year end compliance. We work around-the-clock and seven days a
                        week, to ensure the SMSF’s problem is resolved, and that you are getting the exceptional help.
                    </p>
                    <p>As a full pledged SMSF advisory firm we are armed with experts who can help you with your SMSF
                        accounting needs.</p>
                </div>
                <br>
                <a routerLink="/why-us" class="default-btn"><i class="bx bxs-hot"></i> Read More<span></span></a>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="banner-image mbanner-bg-one"
                        style="background-image: url('assets/img/banner-animation.jpg');">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="animate-banner-image">
                                    <img src="assets/img/banner-animation.jpg" alt="image">
                                </div>
                            </div>
                        </div>
                        <img src="assets/img/banner-animation.jpg" class="mbanner-img" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>OUR ADVANTAGES</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".1s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>POWERFUL ONLINE PORTAL</h3>
                    <p><span class="logo-one-digit">1</span> superfund powerful online portal allows you to get your
                        SMSF information on real time basis and
                        meet your smsf needs at a cost-effective manner 24/7
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bx-customize'></i>
                    </div>
                    <h3>HIGHLY QUALIFIED SMSF PROFESSIONALS</h3>
                    <p>All our smsf work is monitored and reviewed by ATO registered tax agents and audited by ASIC
                        registered SMSF auditors.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".3s">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>FAIR FEES POLICY</h3>
                    <p>Based on our research our fees are very low due to efficiency from our powerful technology which
                        is passed to SMSF’s. Our upfront fixed fees are fully transparent and no surprises after work
                        completed. </p>
                    <p>Full refund (before the tax return lodgment) if you are not happy with our service, no
                        explanation or cause need to be shown, you just need to say hey we are not happy with service
                        and don’t want to continue, can we have a refund.</p>
                </div>
            </div>
        </div>
    </div>
</section>