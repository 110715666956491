import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(
        private _apiService: ApiService
    ) { }

    /**
     * Add Data
     * 
     * @param applyOnline 
     */
    add(json: any) {
        return this._apiService.post(json, 'contact/add');
    }
}
