<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>NEW SMSF SETUP</h1>
            <p>NEW SMSF SETUP</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/img/setup-new-smsf.jpg" alt="image">
                    </div>
                    <div class="article-content">
                        <p>1Superfund can establish a new self managed super fund for you for FREE* (upon
                            instruction to do so; we do not recommend or give advice to do so). We attend to every
                            requirement to setup your SMSF. On receiving your online application & payment we will:</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check-double'></i> Supply you with the SMSF Trust Deed.</li>
                            <li><i class='bx bx-check-double'></i> Supply you with necessary documentation to appoint
                                Trustees</li>
                            <li><i class='bx bx-check-double'></i> Make an election so that your SMSF can be an ATO
                                regulated fund</li>
                            <li><i class='bx bx-check-double'></i> Make an application to the ATO to obtain a Tax File
                                Number & Australian Business Number for your SMSF.</li>
                            <li><i class='bx bx-check-double'></i> Supply you with resolutions and minutes that
                                acknowledges your SMSF as having been established.</li>
                        </ul>
                        <br>
                        <p>*Setup fee is $0 if you choose Individual Trustees, if you require us to setup a Company to
                            be trustee of the SMSF then a setup fee of $599 applies.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>