import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment.prod';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: MetaService
    ) {
        this.metaService.createCanonicalURL();
    this.titleService.setTitle(`About | ${environment.projectName}`);
   }

  ngOnInit() {
    
  }

}
