<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>INVESTMENTS</h1>
            <p>INVESTMENTS</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <img src="assets/img/inventments.jpg" alt="image" style="width: 100%;">
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <p>You need to manage your fund’s investments in the best interests of fund members and in
                            accordance with the law. And you need to separate your fund's investments from the personal
                            and business affairs of fund members, including your own.</p>
                        <p>Provided the governing rules of your fund allow it, your SMSF can generally invest in most
                            investment products including the following:</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check-double'></i> Cash</li>
                            <li><i class='bx bx-check-double'></i> Shares</li>
                            <li><i class='bx bx-check-double'></i> Real Property</li>
                            <li><i class='bx bx-check-double'></i> Unit Trusts</li>
                            <li><i class='bx bx-check-double'></i> Managed Funds</li>
                            <li><i class='bx bx-check-double'></i> WRAP Accounts</li>
                            <li><i class='bx bx-check-double'></i> Loans to non-related parties</li>
                            <li><i class='bx bx-check-double'></i> Bonds</li>
                            <li><i class='bx bx-check-double'></i> Metals</li>
                            <li><i class='bx bx-check-double'></i> Collectables</li>
                            <li><i class='bx bx-check-double'></i> Derivatives</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>