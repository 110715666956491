import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { AppointUsComponent } from './components/pages/appoint-us/appoint-us.component';
import { WhyUsComponent } from './components/pages/why-us/why-us.component';
import { FeesComponent } from './components/pages/fees/fees.component';
import { SetupNewSmsfComponent } from './components/pages/setup-new-smsf/setup-new-smsf.component';
import { SetupSmsfProcessComponent } from './components/pages/setup-smsf-process/setup-smsf-process.component';
import { SmsfSpecialOfferComponent } from './components/pages/smsf-special-offer/smsf-special-offer.component';
import { TakeOnProcessComponent } from './components/pages/take-on-process/take-on-process.component';
import { InvestmentsComponent } from './components/pages/investments/investments.component';
import { ContributionsRolloverComponent } from './components/pages/contributions-rollover/contributions-rollover.component';
import { ApplyOnlineComponent } from './components/pages/apply-online/apply-online.component';
import { SmsfRisksComponent } from './components/pages/smsf-risks/smsf-risks.component';
import { ResourcesTaxComponent } from './components/pages/resources-tax/resources-tax.component';

const routes: Routes = [
    { path: '', component: HomeFourComponent },
    { path: 'home-4', component: HomeFourComponent },
    { path: 'about', component: AboutComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'team', component: TeamComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'blog', component: BlogGridComponent },
    { path: 'blog/:url', component: BlogGridComponent },
    { path: 'blog-2', component: BlogRightSidebarComponent },
    { path: 'blog-details/:url', component: BlogDetailsComponent },

    { path: 'contact', component: ContactComponent },
    { path: 'appoint-us', component: AppointUsComponent },
    { path: 'why-us', component: WhyUsComponent },
    { path: 'fees', component: FeesComponent },
    { path: 'setup-new-smsf', component: SetupNewSmsfComponent },
    { path: 'setup-smsf-process', component: SetupSmsfProcessComponent },
    { path: 'smsf-special-offer', component: SmsfSpecialOfferComponent },
    { path: 'take-on-process', component: TakeOnProcessComponent },
    { path: 'inventments', component: InvestmentsComponent },
    { path: 'contributions-rollover', component: ContributionsRolloverComponent },
    { path: 'apply-online', component: ApplyOnlineComponent },
    { path: 'smsf-risks', component: SmsfRisksComponent },
    { path: 'rollover/:navigation', component: ResourcesTaxComponent },
    { path: 'rollover/:navigation/:subNavigation', component: ResourcesTaxComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
