import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { environment } from '../../../environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    apiUrl: string = environment.apiUrl;
    defaultProfileImage = '/assets/img/favicon.png';
    pagination: any = { totalPages: 0, totalCount: 0, currentPage: 1, perPage: 25, data: [] };

    constructor(
    ) { }

    getFormatedDateTime(date): any {
        return formatDate(date, 'MMM d, yyyy hh:mm aa', 'en-US', '+0530');
    }

    getFormatedDate(date: any): string {
        return formatDate(date, 'MMM d, yyyy', 'en-US', '+0530');
    }

    getDownloadFileUrl(file): string {
        let url = this.apiUrl + 'file/download/';
        if (file !== '') {
            url += file;
        }
        return url;
    }

    getImageUrl(file): string {
        let url = this.apiUrl + 'file/get/';
        if (file !== null && file !== '' && file !== undefined) {
            url += file;
        } else {
            url = this.defaultProfileImage;
        }
        return url;
    }
}