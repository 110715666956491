import { Component, OnInit, ViewChild } from '@angular/core';
import { NewRequestService } from '../../services/new-request.service';
import { ConstantService } from '../../services/constant.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileUploadService } from '../../services/file-upload.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-appoint-us',
  templateUrl: './appoint-us.component.html',
  styleUrls: ['./appoint-us.component.scss']
})
export class AppointUsComponent implements OnInit {

  size: any = 'Normal';
  lang: any = 'en';
  theme: any = 'Light';
  type: any = 'Image';

  @ViewChild('f', { static: true }) form;

  successMessage: any = "";
  errorMessage: any = "";
  commonErrorMsg: boolean = true;
  fileName: any = null;
  progressBarForFile: number = 0;
  formGroup: FormGroup;

  disclosure1 = null;
  disclosure2 = null;

  environment: any = environment;

  constructor(
    private _newRequestService: NewRequestService,
    public _constantService: ConstantService,
    private _fileUploadService: FileUploadService,
    private _formBuilder: FormBuilder,
    private titleService: Title,
    private metaService: MetaService
    ) {
        this.metaService.createCanonicalURL();
    this.titleService.setTitle(`Transfer SMSF | ${environment.projectName}`);
   }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      smsfName: [null, Validators.required],
      smsfTaxFileNumber: [null, Validators.required],
      contractTrusteeFirstName: [null, Validators.required],
      contractTrusteeLastName: [null, Validators.required]
    });
  }

  submit() {
    let data = this.formGroup.getRawValue();
    data['attachment'] = this.fileName;
    this._newRequestService.transfer(data).then((response: any) => {
      if (response && response.status === 'OK') {
        //Reset form
        this.form.resetForm();
        this.disclosure1 = null;
        this.disclosure2 = null;
        this.errorMessage = "";
        this.successMessage = response.message;
        this.commonErrorMsg = false;
      } else {
        this.successMessage = "";
        this.errorMessage = response.message;
        this.commonErrorMsg = false;
      }
    }, error => {
      this.successMessage = "";
      this.errorMessage = "Internal Server Error";
    })
  }

  isValid() {
    if (!this.disclosure1 || this.disclosure1 === ''
      || !this.disclosure2 || this.disclosure2 === ''
    ) {
      return true;
    }
    return false;
  }

  getEmail(email) {
    console.log(email);
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.uploadFile(event.target.files[0]);
    }
  }

  loadImage() {

  }

  removeFile() {
    this._fileUploadService.delete(this.fileName).then((response: any) => {
      if (response && response.status === 'OK') {
      } else {
      }
    }, error => {
    })
  }

  /**
    * Upload File
    * 
    * @param multiPartFile 
    */
  uploadFile(multiPartFile) {
    let formData = new FormData();
    formData.append('file', multiPartFile);
    formData.append('type', 'transfer');
    this._fileUploadService.uploadImage(formData).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progressBarForFile = Math.round(event.loaded / event.total * 100);
          console.log(`Uploaded! ${this.progressBarForFile}%`);
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          this.fileName = event.body.data;
          setTimeout(() => {
            this.progressBarForFile = 0;
          }, 1500);
      }
    });
  }

  //mobile number validation
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  handleSuccess(e) {
    console.log("ReCaptcha", e);
  }
}
