<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Contact Us</h1>
            <p>Drop us Message for any Query</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <br><br>
                        <h3>Contact us by Phone Number</h3>
                        <ul>
                            <li><i class='bx bx-phone-call'></i><a href="tel:1300 92 01 89">1300 92 01 89</a></li>
                        </ul><br><br>
                        <h3>Contact us by Email Address</h3>
                        <ul>
                            <li><i class='bx bx-mail-send'></i><a
                                    href="mailto:service@1superfund.com.au">service@1superfund.com.au</a></li>
                        </ul>
                        <br><br>
                        <h3>Contact us by Address</h3>
                        <ul>
                            <li><i class='bx bx-map'></i><a><span class="logo-one-digit">1</span> superfund </a></li>
                            <li>PO Box 201 </li>
                            <li>Moorebank NSW 1875</li>
                        </ul>
                        <!-- <div class="contact-info">
                            <div class="contact-info-content">
                                <ul class="social">
                                    <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                                </ul> 
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Contact Us</h3>
                        <form id="contactForm" [formGroup]="formGroup" #f="ngForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Name *</mat-label>
                                            <input type="text" matInput placeholder="Enter name" formControlName="name">
                                            <mat-error>Name is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Email *</mat-label>
                                            <input type="text" matInput placeholder="Enter email"
                                                formControlName="email">
                                            <mat-error>Email is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Phone Number *</mat-label>
                                            <input type="number" matInput placeholder="Enter phone number"
                                                formControlName="mobileNumber">
                                            <mat-error>Phone Number is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Subject *</mat-label>
                                            <input type="text" matInput placeholder="Enter subject"
                                                formControlName="subject">
                                            <mat-error>Subject is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Message *</mat-label>
                                            <textarea matInput placeholder="Enter message" formControlName="message"
                                                cols="30" rows="6"></textarea>
                                            <mat-error>Message is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <ngx-recaptcha2 #captchaElem [siteKey]="environment.recaptchaSiteKey"
                                        (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                                        [hl]="lang" [theme]="theme" [type]="type">
                                    </ngx-recaptcha2>
                                </div>
                                <div class="col-lg-12 col-md-12" style="padding-top: 10px;">
                                    <button type="button" class="default-btn" [disabled]="formGroup.invalid" (click)="
                                    submit()"><i class='bx bxs-paper-plane'></i>Send
                                        Message<span></span></button>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <br>
                                    <div class="form-group">
                                        <div class="alert alert-danger fade show" *ngIf="errorMessage != ''">
                                            {{errorMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="errorMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="alert alert-success fade show" *ngIf="successMessage != ''">
                                            {{successMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="successMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>