<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>CONTRIBUTIONS AND ROLLOVERS</h1>
            <p>CONTRIBUTIONS AND ROLLOVERS</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="faq-accordion">
                            <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingOne">
                                        <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                            aria-expanded="false" aria-controls="collapseOne">
                                            CONTRIBUTIONS AND ROLLOVERS <i class="bx bx-plus"></i>
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" role="tabpanel"
                                        aria-labelledby="headingOne" data-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>As an SMSF trustee, you can accept contributions and rollovers for your
                                                members from various sources but there are some restrictions, mostly
                                                depending on the member’s age and the contribution caps.</p>
                                            <p>You need to properly document contributions and rollovers, including the
                                                amount, type and breakdown of components, and allocate them to the
                                                members’ accounts within 28 days of the end of the month in which you
                                                received them.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingTwo">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                            href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            ROLLOVERS <i class="bx bx-plus"></i>
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                                        data-parent="#accordionEx">
                                        <div class="card-body">
                                            <p>A Rollover is when a member transfers some or all of their existing super
                                                between funds.</p>
                                            <p><b>Receiving a Rollover</b></p>
                                            <p>Before rolling over benefits to your SMSF, APRA-regulated super funds
                                                check with our systems to confirm that the person requesting the
                                                rollover is a member of your fund. So make sure your fund membership
                                                details are up to date in our systems and notify us of any changes.</p>
                                            <p>A rollover from another fund is not included in the assessable income of
                                                your fund, unless the rollover amount includes an element untaxed in the
                                                fund.</p>
                                            <p>If it does contain an untaxed element, you include the amount of that
                                                element in the assessable income of your fund – up to the untaxed plan
                                                cap amount – in the financial year the rollover occurs.</p>
                                            <p>If the untaxed element exceeds the untaxed plan cap, the originating fund
                                                should withhold tax – at the top marginal rate plus Medicare levy – from
                                                the amount over the cap before releasing the rollover to your fund. You
                                                add this now-taxed amount to the tax-free component of the rolled-over
                                                amount.</p>
                                            <p><b>Example: Rollover with an untaxed element</b></p>
                                            <p>On 5 September 2014, Tom asks his fund to roll over his super interest of
                                                $1.5 million. This is an untaxed element. The untaxed plan cap amount
                                                for 2014–15 is $1.355 million, meaning that Tom's rollover amount
                                                exceeds the cap by $145,000. The originating fund must withhold tax of
                                                $71,050 (49% of $145,000).</p>
                                            <p>The amounts reported by the originating fund on the rollover benefits
                                                statement will be $73,950 ($145,000 – $71,050) at the 'tax-free
                                                component' label and $1.355 million at the 'element untaxed in the fund'
                                                label. Tom's SMSF will report the $1.355 million as income at the
                                                'personal contributions' label in the SMSF annual return.</p>
                                            <p><b>Making and reporting a rollover</b></p>
                                            <p>When rolling over your members' benefits to another super fund, you need
                                                to:</p>
                                            <ul class="features-list">
                                                <li><i class='bx bx-check-double'></i> Confirm that the receiving fund
                                                    is complying</li>
                                                <li><i class='bx bx-check-double'></i> Complete a Rollover benefits
                                                    statement and:
                                                    <ul class="features-list">
                                                        <li><i class='bx bx-check-double'></i> Give the completed
                                                            statement to the receiving fund, either with the payment or
                                                            within seven days</li>
                                                        <li><i class='bx bx-check-double'></i>Give a copy to the member
                                                            within 30 days</li>
                                                        <li><i class='bx bx-check-double'></i>Keep a copy for your
                                                            records for five years.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingThree">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                            href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            PERSONAL CONTRIBUTIONS – DEDUCTIONS<i class="bx bx-plus"></i>
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" role="tabpanel"
                                        aria-labelledby="headingThree" data-parent="#accordionEx">
                                        <div class="card-body">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s, when an unknown printer took a galley of type and scrambled it to
                                            make a type specimen book.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" role="tab" id="headingFour">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                            href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            CONTRIBUTIONS YOU CAN ACCEPT <i class="bx bx-plus"></i>
                                        </a>
                                    </div>
                                    <div id="collapseFour" class="collapse" role="tabpanel"
                                        aria-labelledby="headingFour" data-parent="#accordionEx">
                                        <div class="card-body">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s, when an unknown printer took a galley of type and scrambled it to
                                            make a type specimen book.
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-0">
                                    <div class="card-header" role="tab" id="headingFive">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                            href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            CONTRIBUTION CAPS <i class="bx bx-plus"></i>
                                        </a>
                                    </div>
                                    <div id="collapseFive" class="collapse" role="tabpanel"
                                        aria-labelledby="headingFive" data-parent="#accordionEx">
                                        <div class="card-body">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the
                                            1500s, when an unknown printer took a galley of type and scrambled it to
                                            make a type specimen book.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>