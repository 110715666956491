import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class NewRequestService {

    constructor(
        private _apiService: ApiService
    ) { }

    /**
     * Add Data
     * 
     * @param applyOnline 
     */
    add(json: any) {
        return this._apiService.post(json, 'new/request/add');
    }

    /**
     * Transfer request
     * 
     * @param json 
     */
    transfer(json: any) {
        return this._apiService.post(json, 'transfer/request/add');
    }

    /**
     * Get All with pagination
     * 
     * @param pagination 
     */
    getAllWithPagination(pagination: any) {
        return this._apiService.post(pagination, 'new/request/getAllWithPagination');
    }

    /**
     * Get data by id
     * 
     * @param id 
     */
    getDataById(id: any) {
        return this._apiService.get(`new/request/getById/${id}`);
    }

    /**
     * Delete data by id
     * 
     * @param id 
     */
    deleteDataById(id) {
        return this._apiService.post(null, `new/request/deleteById/${id}`);
    }

    /**
     * Update data status
     * 
     * @param formData 
     * @param id 
     */
    updateDataStatus(json: any) {
        return this._apiService.post(json, `new/request/updateStatus/`);
    }
}
