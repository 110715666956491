<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Transfer SMSF</h1>
            <p>Existing SMSF</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100" style="background-color: whitesmoke;">
    <div class="container">
        <div>
            <div class="row">
                <div class="col-lg-1 col-md-1"></div>
                <div class="col-lg-10 col-md-10">
                    <div class="contact-form contact-inner" style=" border-radius: 10px; padding: 50px;">
                        <form id="contactForm" [formGroup]="formGroup" #f="ngForm" (ngSubmit)="submit()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <!--Email Field-->
                                        <mat-form-field appearance="outline">
                                            <mat-label>Email *</mat-label>
                                            <input matInput type="email" placeholder="Enter email address"
                                                formControlName="email">
                                            <mat-error
                                                *ngIf="formGroup.controls.email.invalid && (formGroup.controls.email.dirty || formGroup.controls.email.touched)">
                                                <ng-container *ngIf="formGroup.controls.email.errors.required">
                                                    Email is required.
                                                </ng-container>
                                                <ng-container *ngIf="formGroup.controls.email.errors.email">
                                                    Email must be a valid email address
                                                </ng-container>
                                            </mat-error>
                                            <mat-icon matSuffix>email</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Mobile *</mat-label>
                                        <input matInput type="number" placeholder="Enter mobile number"
                                            formControlName="mobile">
                                        <mat-error
                                            *ngIf="formGroup.controls.mobile.invalid && (formGroup.controls.mobile.dirty || formGroup.controls.mobile.touched)">
                                            <ng-container *ngIf="formGroup.controls.mobile.errors.required">
                                                Mobile is required.
                                            </ng-container>
                                            <ng-container
                                                *ngIf="formGroup.controls.mobile.errors.minlength || formGroup.controls.mobile.errors.maxlength">
                                                Mobile must be 10 digits
                                            </ng-container>
                                        </mat-error>
                                        <mat-icon matSuffix>phone</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>SMSF Name *</mat-label>
                                        <input matInput type="text" placeholder="Enter SMSF Name"
                                            formControlName="smsfName">
                                        <mat-error
                                            *ngIf="formGroup.controls.smsfName.invalid && (formGroup.controls.smsfName.dirty || formGroup.controls.smsfName.touched)">
                                            <ng-container *ngIf="formGroup.controls.smsfName.errors.required">
                                                SMSF Name is required.
                                            </ng-container>
                                            <ng-container *ngIf="formGroup.controls.smsfName.errors.minlength">
                                                SMSF Name must be at least 4 characters long.
                                            </ng-container>
                                        </mat-error>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>SMSF Tax File Number *</mat-label>
                                        <input matInput type="text" placeholder="Enter SMSF Tax File Number"
                                            formControlName="smsfTaxFileNumber">
                                        <mat-error
                                            *ngIf="formGroup.controls.smsfTaxFileNumber.invalid && (formGroup.controls.smsfTaxFileNumber.dirty || formGroup.controls.smsfTaxFileNumber.touched)">
                                            <ng-container *ngIf="formGroup.controls.smsfTaxFileNumber.errors.required">
                                                SMSF Tax File Number is required.
                                            </ng-container>
                                            <ng-container *ngIf="formGroup.controls.smsfTaxFileNumber.errors.minlength">
                                                SMSF Tax File Number must be at least 4 characters long.
                                            </ng-container>
                                        </mat-error>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Contract Trustee First Name *</mat-label>
                                        <input matInput type="text" placeholder="Enter Contract Trustee First Name"
                                            formControlName="contractTrusteeFirstName">
                                        <mat-error
                                            *ngIf="formGroup.controls.contractTrusteeFirstName.invalid && (formGroup.controls.contractTrusteeFirstName.dirty || formGroup.controls.contractTrusteeFirstName.touched)">
                                            <ng-container
                                                *ngIf="formGroup.controls.contractTrusteeFirstName.errors.required">
                                                Contract Trustee First Name is required.
                                            </ng-container>
                                            <ng-container
                                                *ngIf="formGroup.controls.contractTrusteeFirstName.errors.minlength">
                                                Contract Trustee First Name must be at least 4 characters long.
                                            </ng-container>
                                        </mat-error>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Contract Trustee Last Name *</mat-label>
                                        <input matInput type="text" placeholder="Contract Trustee Last Name"
                                            formControlName="contractTrusteeLastName">
                                        <mat-error
                                            *ngIf="formGroup.controls.contractTrusteeLastName.invalid && (formGroup.controls.contractTrusteeLastName.dirty || formGroup.controls.contractTrusteeLastName.touched)">
                                            <ng-container
                                                *ngIf="formGroup.controls.contractTrusteeLastName.errors.required">
                                                Contract Trustee Last Name is required.
                                            </ng-container>
                                            <ng-container *ngIf="formGroup.controls.name.errors.minlength">
                                                Contract Trustee Last Name must be at least 4 characters long.
                                            </ng-container>
                                        </mat-error>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="">Confirm you have read our Disclosures <span>*</span></label>
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures1.invalid && (disclosures1.dirty || disclosures1.touched)">
                                            <div *ngIf="disclosures1.errors.required" class="error">
                                                Please tick and confirm that you have read our Disclosures .
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures1="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="disclosure1"
                                                [ngModelOptions]="{standalone: true}">
                                            I hereby authorise to take on my SMSF annual compliance .
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures2.invalid && (disclosures2.dirty || disclosures2.touched)">
                                            <div *ngIf="disclosures2.errors.required" class="error">
                                                Please tick and confirm that you have read our Disclosures .
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures2="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="disclosure2"
                                                [ngModelOptions]="{standalone: true}">
                                            I acknowledge I have read and agree to the terms and conditions.
                                        </p>
                                    </div>

                                    <div>
                                        <label for="Attachment">Attach most recent tax return</label>
                                        <br>
                                        <input type="file" (change)="onSelectFile($event)">
                                        <div class="progress form-group" *ngIf="progressBarForFile > 0">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                                [style.width.%]="progressBarForFile">
                                            </div>
                                        </div>
                                        <span style="color: darkblue;" *ngIf="progressBarForFile > 0 ">
                                            {{progressBarForFile}} % </span>
                                    </div>

                                    <!-- <re-captcha (resolved)="resolved($event)"
                                        siteKey="6LdCZzYgAAAAAEo6lxKIi9PZasCbdaY32a1BHYth"></re-captcha> -->

                                    <!-- <ngx-recaptcha2 #captchaElem [siteKey]="environment.recaptchaSiteKey"
                                        (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                                        [hl]="lang" [theme]="theme" [type]="type">
                                    </ngx-recaptcha2> -->

                                    <br>
                                    <div class="form-group">
                                        <button type="submit" class="default-btn"
                                            [disabled]="formGroup.invalid || isValid()"><i
                                                class='bx bxs-paper-plane'></i>
                                            Submit
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="alert alert-danger fade show" *ngIf="errorMessage != ''">
                                            {{errorMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="errorMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="alert alert-success fade show" *ngIf="successMessage != ''">
                                            {{successMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="successMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <!-- <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input matInput type="email" [(ngModel)]="formModel.email"
                                                [ngModelOptions]="{standalone: true}" [email]="true" #email="ngModel"
                                                placeholder="Enter email" required>
                                            <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                                                <span *ngIf="email.errors.required">Email is required.</span>
                                                <span *ngIf="email.errors.email">Enter a valid email address.</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Phone Number</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.mobileNumber"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter phone number"
                                            required (keypress)="keyPress($event)" minlength=10 maxlength=10>
                                        <mat-error>Phone Number is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>SMSF Name</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.smsfName"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Enter Preferred SMSF Name" required>
                                        <mat-error>SMSF Name is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>SMSF Tax File Number</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.smsfTaxFileNumber"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter Tax File Number"
                                            required>
                                        <mat-error>SMSF Tax File Number is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Contract Trustee First Name</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.contractTrusteeFirstName"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Enter Contract Trustee First Name" required>
                                        <mat-error>Contract Trustee First Name is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Contract Trustee Last Name</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.contractTrusteeLastName"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Enter Contract Trustee Last Name" required>
                                        <mat-error>Contract Trustee Last Name is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="">Confirm you have read our Disclosures <span>*</span></label>
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures1.invalid && (disclosures1.dirty || disclosures1.touched)">
                                            <div *ngIf="disclosures1.errors.required" class="error">
                                                Please tick and confirm that you have read our Disclosures .
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures1="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="formModel.disclosures1"
                                                [ngModelOptions]="{standalone: true}">
                                            I hereby authorise to take on my SMSF annual compliance .
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures2.invalid && (disclosures2.dirty || disclosures2.touched)">
                                            <div *ngIf="disclosures2.errors.required" class="error">
                                                Please tick and confirm that you have read our Disclosures .
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures2="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="formModel.disclosures2"
                                                [ngModelOptions]="{standalone: true}">
                                            I acknowledge I have read and agree to the terms and conditions.
                                        </p>
                                    </div>

                                    <div>
                                        <label for="Attachment">Attach most recent tax return</label>
                                        <br>
                                        <input type="file" (change)="onSelectFile($event)">
                                        <div class="progress form-group" *ngIf="progressBarForFile > 0">
                                            <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                                [style.width.%]="progressBarForFile">
                                            </div>
                                        </div>
                                        <span style="color: darkblue;" *ngIf="progressBarForFile > 0 ">
                                            {{progressBarForFile}} % </span>
                                    </div>
                                    <br>
                                    <div class="form-group">
                                        <button type="button" class="default-btn" [disabled]="isValid()" (click)="
                                            submit()"><i class='bx bxs-paper-plane'></i>
                                            Submit
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="alert alert-danger fade show" *ngIf="errorMessage != ''">
                                            {{errorMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="errorMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="alert alert-success fade show" *ngIf="successMessage != ''">
                                            {{successMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="successMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>