<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Resources > {{navigationData?.name}}</h1>
            <p>Thinking about self-managed super</p>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="faq-accordion">
                    <br>
                    <br>
                    <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <ng-container *ngFor="let element of navigationList">
                            <div class="card">
                                <div class="card-header" role="tab" id="headingTwo"
                                    [ngClass]="{'nav-active' : element.url === subNavigationUrl}">
                                    <a class="collapsed"
                                        [routerLink]="'/rollover/' + navigationUrl + '/' + element.url">
                                        {{element.name}}
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-8" *ngIf="navigationData">
                <div class="about-content">
                    <h3 style="font-size: 20px; font-weight: bold;">{{navigationData.name}}</h3>
                    <div [innerHTML]="navigationData.content"></div>
                </div>
            </div>
        </div>
    </div>
</section>