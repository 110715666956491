import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    constructor(
        private _apiService: ApiService,
        private _formBuilder: FormBuilder
    ) { }

    createCommentForm(element: any): FormGroup {
        return this._formBuilder.group({
            blogId: null,
            type: "user",
            comment: null,
            name: element ? element.name : null,
            email: element ? element.email : null,
            website: element ? element.website : null,
            isSaveDataToBrowser: element ? element.isSaveDataToBrowser : false
        });
    }

    getDataListWithPagination(requestBody: any) {
        return this._apiService.post(requestBody, 'blog/getAllWithPagination');
    }

    getDataByUrl(url: any) {
        return this._apiService.get(`blog/getByUrl?url=${url}`);
    }

    addComment(data: any) {
        return this._apiService.post(data, 'comment/add');
    }

    getCategoryList() {
        return this._apiService.get(`category/all`);
    }

    getPopularBlogs() {
        return this._apiService.get(`blog/getPopular`);
    }

}
