import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from '../../services/constant.service';

@Component({
  selector: 'app-company-details-popup',
  templateUrl: './company-details-popup.component.html',
  styleUrls: ['./company-details-popup.component.css']
})
export class CompanyDetailsPopupComponent implements OnInit {

  formModel: any = {
    doYouHaveAnExistingCompanyToActAsTheCorporateTrustee: null,
    doYouWantToEstablishACompanyNow: null,
    isTheSolePurposeOfTheCompanyToActAsTheTrusteeOfTheSMSF: null,
    preferredCompanyName: null,
  }

  constructor(
    public matDialogRef: MatDialogRef<CompanyDetailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _constantService: ConstantService
  ) {
    matDialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this._data) {
      this.formModel = this._data;
    }
  }

  isValid() {
    if (
      !this.formModel.doYouHaveAnExistingCompanyToActAsTheCorporateTrustee || this.formModel.doYouHaveAnExistingCompanyToActAsTheCorporateTrustee === ''
      || !this.formModel.doYouWantToEstablishACompanyNow || this.formModel.doYouWantToEstablishACompanyNow === ''
      || !this.formModel.isTheSolePurposeOfTheCompanyToActAsTheTrusteeOfTheSMSF || this.formModel.isTheSolePurposeOfTheCompanyToActAsTheTrusteeOfTheSMSF === ''
      || this.formModel.doYouWantToEstablishACompanyNow === 'yes' && (!this.formModel.preferredCompanyName || this.formModel.preferredCompanyName === '')
    ) {
      return true;
    }
    return false;
  }

  submit() {
    this.matDialogRef.close(this.formModel);
  }
}
