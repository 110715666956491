import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment.prod';
import { MetaService } from '../../services/meta.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-resources-tax',
  templateUrl: './resources-tax.component.html',
  styleUrls: ['./resources-tax.component.scss']
})
export class ResourcesTaxComponent implements OnInit {
  navigationUrl: any;
  subNavigationUrl: any;
  navigationList: any;
  navigationData: any;

  constructor(
    private titleService: Title,
    private _navigationService: NavigationService,
    private _activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private metaService: MetaService
  ) {
    this.metaService.createCanonicalURL();
    this.navigationUrl = _activatedRoute.snapshot.params.navigation;
    this.getSubNavigationList();
    if (_activatedRoute.snapshot.params.subNavigation) {
      this.subNavigationUrl = _activatedRoute.snapshot.params.subNavigation;
      this.getNavigationContent();
    }

    this._activatedRoute.params.subscribe(routeParams => {
      this.navigationUrl = routeParams.navigation;
      this.subNavigationUrl = routeParams.subNavigation;
      this.getSubNavigationList();
      this.getNavigationContent();
    });
  }

  getSubNavigationList() {
    this._navigationService.getSubNavigationListByUrl(this.navigationUrl).then((response: any) => {
      this.navigationList = response.data;
      if (!this._activatedRoute.snapshot.params.subNavigation) {
        if (this.navigationList.length > 0) {
          this.subNavigationUrl = this.navigationList[0].url;
          this.getNavigationContent();
        }
      }
    })
  }

  getNavigationContent() {
    this._navigationService.getDataByUrl(this.subNavigationUrl).then((response: any) => {
      this.navigationData = response.data;
      this.navigationData.content = this.navigationData.content.replace("\"", "");
      this.navigationData.content = this.sanitizer.bypassSecurityTrustHtml(this.navigationData.content);
      this.titleService.setTitle(`Rollover | ${this.navigationData.name} | ${environment.projectName}`);
    })
  }

  ngOnInit(): void {
  }

}
