import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { ContactService } from '../../services/contact.service';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  size: any = 'Normal';
  lang: any = 'en';
  theme: any = 'Light';
  type: any = 'Image';

  @ViewChild('f', { static: true }) form;
  formGroup: FormGroup;
  errorMessage: string = "";
  successMessage: any = "";

  environment: any = environment;

  constructor(
    private _contactService: ContactService,
    private _formBuilder: FormBuilder,
    private titleService: Title,
    private metaService: MetaService
    ) {
        this.metaService.createCanonicalURL(); 
    this.titleService.setTitle(`Contact | ${environment.projectName}`);
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      mobileNumber: [null, Validators.required],
      subject: [null, Validators.required],
      message: [null, Validators.required]
    });
  }

  submit() {
    const data = this.formGroup.getRawValue();
    this._contactService.add(data).then((response: any) => {
      if (response && response.status === 'OK') {
        this.errorMessage = "";
        this.successMessage = response.message;
        this.form.resetForm();
      } else {
        this.successMessage = "";
        this.errorMessage = response.message;
      }
    }, error => {
      this.successMessage = "";
      this.errorMessage = "Internal Server Error";
    })
  }

  handleSuccess(e) {
    console.log("ReCaptcha", e);
  }
}
