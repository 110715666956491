<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Why should I choose <span class="logo-one-digit">1</span> superfund?</h1>
            <p>It’s too simple </p>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">SWITCH TO 1SUPERFUND FOR BETTER SERVICE .</span>
                    <!-- <h2>Take your business to the next level</h2> -->
                    <h3 style="color: #13c4a1; font-weight: bold;">Is your SMSF Tax Return Due?</h3>
                    <p>Are you struggling with your Super fund compliance, tired of paying excessive fees, accountant
                        not returning your calls or finding a good auditor, well you are not alone!</p>
                    <p style="color: red;">Is your current SMSF accountant charging you $2000 + to do your SMSF Tax
                        Return?</p>
                    <p>We are here to help and take the worry from you</p>
                    <p>We at, <span class="logo-one-digit">1</span> superfund fund can assist, we are committed to
                        provide you with highest quality one stop
                        SMSF accounting and annual compliance service at a fraction of the high street cost. You can
                        communicate with us 24/7 through email or phone. For your peace of mind, we assure you all our
                        tax agent and independent auditors are registered with ATO and ASIC.</p>
                    <p><span class="logo-one-digit">1</span> superfund specialist accountants can lodge your SMSF Tax
                        Returns - Professionally & Quickly!
                        Our prices are very fairly priced, as we have streamlined the process and our powerful
                        technology allows us to offer a quality service at a great price. </p>
                    <p style="color: red;">Better still, You are not bound to use our restrictive investment platform or
                        buy other financial products from us which some provider make you do and obtains commission
                        from, These amount to huge direct and indirect savings!</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/why-u.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="section-title">
            <h2>Our Amazing Features</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>FIXED LOW YEARLY FEES </h3>
                    <p>FIXED LOW YEARLY FEES = GREATER SAVINGS FOR YOUR RETIREMENT </p>
                    <p>You don’t have to be an accounting genius to figure out that!</p>
                    <p>We charge a low fee while you are free to make your own investment choices. You are not bound to
                        use our restrictive investment platform or buy other financial products from us.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>DEDICATED SUPPORT </h3>
                    <p>We offer 24/7 support. You will always be able to speak directly to your own dedicated client
                        manager based in Australia. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>NATIONWIDE SERVICE </h3>
                    <p>While our head office is in Sydney, due to being online, we could scale and serving clients
                        Australia wide. That allows our client save further on travel cost. We serve trustees,
                        accountants and financial planners in providing annual SMSF compliance service. 1 super can be
                        your trusted accountant for the life of the Self-Managed Superfund</p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>NO MINIMUM OR MAXIMUM BALANCE REQUIRED</h3>
                    <p>Whilst a large start up amount is recommended the Australian Securities and Investments Comission
                        (ASIC) do not stipulate a minimum balance to open up a SMSF. Regardless of the size or
                        complexity of your fund Just Superfund always charge the same fixed fee.</p>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>FULL YEARLY COMPLIANCE UNDER ONE ROOF</h3>
                    <p><span class="logo-one-digit">1</span> superfund efficiently handles all your yearly SMSF
                        reporting obligations including financial
                        statement preparation, taxation, SMSF audit* for you regardless of the size or type of
                        investment. We do take care of funds with property, local shares, international shares, managed
                        funds, crypto, precious metal or collectables. </p>
                    <p>*As by regulation auditor need to be independent, we use third party independent ASIC registered
                        auditor from the panel, we pay their fees, so you do not have to go through the hassle of
                        finding a good auditor or pay additional fees.</p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-customize'></i>
                    </div>
                    <h3>FREE LEARNING RESOURCES</h3>
                    <p>JUST SUPERFUND provides an extensive information enabling you to educate yourself about
                        establishing and running your own SMSF. Our information is being constantly expanded and updated
                        to meet your education needs.</p>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-slider-alt'></i>
                    </div>
                    <h3>MEET AND EXCEED LEGISLATIVE REQUIREMENTS</h3>
                    <p>We follow all the legislative SMSF annual reporting requirements set by law and the professional
                        accounting bodies and the auditing & assurance standard board, this gives you a 100% guarantee
                        that your fund is absolutely squeaky clean.</p>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>PREMIUM SERVICE GUARANTEE </h3>
                    <p>All our staff goes through rigorous training to maintain and provide you with an accurate service
                        to avoid getting into trouble with regulatory bodies. All our smsf work is monitored and
                        reviewed by ATO registered tax agents and audited by ASIC registered SMSF auditors.</p>
                    <p>Key thing that makes u super different is not any of the above but our empathy towards clients
                        and willingness to go extra miles for our client.</p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-reset'></i>
                    </div>
                    <h3>SMSF EXPERTS</h3>
                    <p>Given that we specialise in the provision of self managed super fund taxation and audit services,
                        we are dedicated to providing continued assistance and support to our clients. This includes
                        providing taxation advice and information on superannuation rules that enable you to derive the
                        maximum benefit out of your super fund.</p>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow fadeInRight" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>QUICK TURNAROUD</h3>
                    <p>We understand your need to meet the deadline and get on with other things life have in offer! Our
                        efficient process and powerful technology allow us to provide with you 5 Working days turnaround
                        guarantee. </p>
                    <p>In case our negligence causes any penalty from ATO for delayed lodgment – our special insurance
                        allows us to pay the penalty for SMSF. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>APPOINT 1SUPERFUND TODAY!</h2>
            <p>These are just a few of the many benefits of appointing 1Superfund to manage your Self Manages Super
                Fund requirements. Call 1Superfund on 1300 445 678 and experience our 5 star service for yourself.
            </p>
            <a routerLink="/appoint-us" class="default-btn"><i class="bx bxs-hot"></i> Appoint Us Now<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>