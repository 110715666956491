<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Transparent Pricing</h2>
            <p>No surprise – fixed agreed pricing </p>
        </div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Yearly Compliance</a></li>
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Additional Services</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>SMSF ESTABLISHMENT
                                    </h3>
                                </div>
                                <div class="price">
                                    From $0.00 *
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Full SMSF setup service </li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF trust deed preparation</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF setup minutes</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF member register </li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF file opening</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF document storage</li>

                                    <li><i class="bx bxs-badge-check"></i> Dedicated SMSF account manager</li>
                                </ul>
                                <p>*Price varies as some smsf need custom trust deed, bare trust deed, corporate trustee
                                    or custom service, please email us an agent will call or email you to give detailed
                                    quote. </p>
                                <div class="btn-box">
                                    <a routerLink="/appoint-us" class="default-btn"><i class="bx bxs-hot"></i> Try It
                                        Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>YEAR END FULL COMPLIANCE</h3>
                                </div>
                                <div class="price">
                                    From $1099.00*
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> No additional SMSF transfer over fees from
                                        existing provider
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> No additional software conversion fees </li>

                                    <li><i class="bx bxs-badge-check"></i> Full SMSF year end compliance</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF financial statement preparation
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF balance sheet preparation</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF profit and Loss preparation</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF member statement preparation</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF tax return preparation
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF audit arrangement </li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF audit conducted by independent panel
                                        auditor (fees include in the package)
                                    </li>
                                </ul>
                                <p>* As individual smsf is different in terms of risk, complexity and service needs fees
                                    varies, please email us an agent will call or email you to give detailed obligation
                                    free quote. </p>
                                <div class="btn-box">
                                    <a routerLink="/appoint-us" class="default-btn"><i class="bx bxs-hot"></i> Try It
                                        Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>SMSF AUDIT</h3>
                                </div>
                                <div class="price">
                                    From $399.00*
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Audit by ASIC registered SMSF auditor
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Fully independent auditor </li>

                                    <li><i class="bx bxs-badge-check"></i> Review your trust deed</li>

                                    <li><i class="bx bxs-badge-check"></i> Review your investment strategy</li>

                                    <li><i class="bx bxs-badge-check"></i> Review books of accounts of your fund</li>

                                    <li><i class="bx bxs-badge-check"></i> Perform analytical review to ascertain health
                                        of your investments</li>

                                    <li><i class="bx bxs-badge-check"></i> Review and compare your funds return with
                                        average market return.
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Check compliance of your fund with SIS act
                                        and regulations.</li>

                                    <li><i class="bx bxs-badge-check"></i> Advice you any non-material breach through
                                        management letter
                                    </li>
                                </ul>
                                <p>* As individual smsf is different in terms of risk, complexity and service needs fees
                                    varies, please email us an agent will call or email you to give detailed obligation
                                    free quote. </p>
                                <div class="btn-box">
                                    <a routerLink="/appoint-us" class="default-btn"><i class="bx bxs-hot"></i> Try It
                                        Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>SMSF TAX RETURN </h3>
                                </div>
                                <div class="price">
                                    From $399
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> TAX return by ATO registered tax agent
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Preparation of tax return</li>

                                    <li><i class="bx bxs-badge-check"></i>Communication with auditor </li>

                                    <li><i class="bx bxs-badge-check"></i> Communication with financial accountant
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> ATO tax audit support </li>

                                    <li><i class="bx bxs-badge-check"></i> Multiple and outstanding tax return support
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> ATO submission to consider waiving penalty
                                    </li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/appoint-us" class="default-btn"><i class="bx bxs-hot"></i> Try It
                                        Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>OTHER SERVICES</h3>
                                </div>
                                <div class="price">
                                    From $55
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> ABN and TFN registration $220
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Electronic service address $220</li>

                                    <li><i class="bx bxs-badge-check"></i> Super rollover assistance- each rollover $220
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Actuarial Certificate to provide best tax
                                        saving to your SMSF - $220
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF Trust deed variation $220</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF full trust deed update $550</li>

                                    <li><i class="bx bxs-badge-check"></i> Pension setup minutes $220</li>

                                    <li><i class="bx bxs-badge-check"></i> Transition to retirement setup $220
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> TBAR reporting $220</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF death benefit nomination $220
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Investment strategy documentation $110</li>
                                    <li><i class="bx bxs-badge-check"></i> ASIC yearly company compliance $110</li>

                                    <li><i class="bx bxs-badge-check"></i> ASIC company agent services $110</li>
                                    <li><i class="bx bxs-badge-check"></i> Title search $55</li>
                                    <li><i class="bx bxs-badge-check"></i> Audit contravention report $110</li>
                                    <li><i class="bx bxs-badge-check"></i> ASIC company formation $990</li>
                                    <li><i class="bx bxs-badge-check"></i> SMSF closing formalities $220</li>
                                    <li><i class="bx bxs-badge-check"></i> Any services not listed above are to be
                                        quoted</li>
                                </ul>
                                <div class="btn-box">
                                    <a routerLink="/appoint-us" class="default-btn"><i class="bx bxs-hot"></i> Try It
                                        Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>VALUE ADDED SERVICES </h3>
                                </div>
                                <div class="price">
                                    From $0.00 *
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> General SMSF rules - FREE
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> General legal tax minimisation tips - FREE
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> General detailed superannuation rules - FREE
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i> Dedicated SMSF account manager- FREE
                                    </li>

                                    <li><i class="bx bxs-badge-check"></i>SMSF registered office - FREE</li>

                                    <li><i class="bx bxs-badge-check"></i> ASIC company registered office- FREE</li>

                                    <li><i class="bx bxs-badge-check"></i> SMSF Running manual – FREE</li>
                                </ul>
                                <p>* We can only provide you general information, ASIC and ATO bars accountants from
                                    providing any specific advice related to smsf structure, contribution, pension or
                                    investment strategy. If you require specific advice, you should do your own research
                                    and if required contact a licensed financial planner.</p>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free
                                        Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h1>Get to know about <span style="color: red;">1</span></h1>
                        <h2 style="color: red;">Superfund</h2>
                        <p style="color: red;">Just wondering if the setup fees starts from free, how you make money to
                            pay your bills?</p>
                        <p>Quote : According to ASIC deputy chairman Peter Kell, consumers should be on the lookout for
                            services that advertise themselves as “free”. </p>
                        <p>There are some providers who often setup smsf and smsf compliance for free – but user is
                            restricted to use their platform and buy other financial products from them, from which they
                            might get hefty commission.</p>
                        <p>At <span class="logo-one-digit">1</span> superfund SMSF you are not restricted to use any specific investment platform, SMSF have
                            true freedom in the way invest their asset as permitted by SIS act. It fees start from Zero
                            setup fees with very low yearly compliance fees offering great long-term savings.</p>
                        <p>With <span class="logo-one-digit">1</span> superfund while some SMSF setup use our free service, many <span class="logo-one-digit">1</span> superfund clients choose
                            a premium service with a fee based on complexity and service needs. Overall, we aim to make
                            small profit through our smart technology and streamlined process while sharing the
                            efficiency gain with SMSF trustees. </p>
                        <p>Please feel free to send us an email we will provide you an obligation free quote, based on
                            our research <span class="logo-one-digit">1</span> superfund fees is one of the lowest better still we do not restrict SMSF to
                            use any specific platform and buy other financial products from us or any of our business
                            partner.</p>
                        <br>
                        <p style="color: red;">Do <span class="logo-one-digit">1</span> superfund provide a very bad service and don’t respond to our emails
                            for months, asking as the fees are very low?</p>
                        <p>We understand your concern! At <span class="logo-one-digit">1</span> superfund you can communicate us 24/7, we always reply to
                            your emails within 4 working hours with solution to your specific needs. While for the entry
                            level service communication is done via email, you can call us 7 days a week in our sydney
                            service delivery center, to get update on your returns and accounts, and we guarantee to
                            reply to your emails within 4 working hours. We also provide premium service where you can
                            talk to your accountant on technical matters regular basis and obtain a custom personalized
                            service.</p>
                        <br>
                        <p style="color: red;">an I just take the SMSF setup service and not use your services anymore?
                            and does you superfund keep as hostage refusing to provide our documents?</p>
                        <p>Yes, <span class="logo-one-digit">1</span> superfund truly believes your super your way , you can take SMSF set up service or
                            yearly compliance service and move to any other provider anytime you feel better for you .
                            If you change your mind whatever the reason, we will fully cooperate with you and provide
                            all your data to you and your new provider within 24 hours. </p>
                        <p>Even further we will make a full refund for any fees charged for which even we have done some
                            of the work but the final services are not delivered i.e., SMSF audit report not issued and
                            tax return is not lodged.</p>
                        <br>
                        <p style="color: red;">Why <span class="logo-one-digit">1</span> superfund charges a fee from yearly compliance fees and is the
                            initial fees we agree fixed for the lifetime of SMSF?</p>
                        <p>As individual smsf is different in terms of risk, complexity and service needs fees varies,
                            please email us an agent will call or email you to give detailed obligation free quote. We
                            are confident, based on our research our fees is one of the lowest in total (while we do not
                            charge clients indirectly via commissions and kickback) , if the quote is not upto your
                            expectation for the services you require , no obligation !</p>
                        <p>Better still we will send you SMSF running manual and other useful documents and tips totally
                            FREE of charge as thank you for your time.</p>
                        <p>As time progress many things changes notably the SMSF complexity, the overall minimum wage
                            rate, interest rate, inflation, regulation and overall market competition so it’s hard to
                            predict what will be the price in future. As we truly believe in your super your way! we do
                            not cage you to a lifetime contract. We review the price each year at 1 July and give smsf
                            individualized obligation free quote which may be higher or lower than previous year
                            considering all the factors .</p>
                        <br>
                        <p style="color: red;">Do you guarantee we will make more money by using an SMSF compared to
                            APRA regulated retail
                            or industry fund? </p>
                        <p><span class="logo-one-digit">1</span> superfund do not advice or recommend whether setting up a superfund is right or beneficial
                            for you in your circumstances. Trustees need to make their own research whether it is right
                            for them and if required contact a licensed financial planner. </p>
                        <p>We do not advice where you can invest your fund or no invest your fund, trustees need to do
                            their own research or contact a licensed financial planner if they need assistance. </p>
                        <p>There are times when SMSF underperform APRA regulated funds and vice versa. It depends on the
                            investment choices you make and market movements. One thing to keep in mind retail funds
                            have full time experts making investment decisions which individual SMSF may not have. </p>
                        <p>Running a SMSF is a big commitment and responsibility for your retirement, trustees are
                            obliged to ensure smsf complies with sis act provisions, it gets audited by independent smsf
                            auditor and a timely tax return is lodged. </p>
                        <br>
                        <p style="color: red;">Can I pay the yearly SMSF compliance invoice from SMSF bank account as I
                            do not want to pay out of pocket? </p>
                        <p>You can! in fact you must pay your smsf compliance and smsf related fees from your SMSF.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area pb-100 pt-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Lets get on board to experience <span class="logo-one-digit">1</span> superfund premium service </p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>