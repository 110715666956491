import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class ConstantService {

    STATE_LIST: any = [
        { id: "ACT", value: "ACT" },
        { id: "NSW", value: "NSW" },
        { id: "NT", value: "NT" },
        { id: "QLD", value: "QLD" },
        { id: "SA", value: "SA" },
        { id: "TAS", value: "TAS" },
        { id: "VIC", value: "VIC" },
        { id: "WA", value: "WA" }
    ]

    PERSON_LIST: any = [
        { id: 1, value: '1 People' },
        { id: 2, value: '2 Peoples' },
        { id: 3, value: '3 Peoples' },
        { id: 4, value: '4 Peoples' },
        { id: 5, value: '5 Peoples' },
        { id: 6, value: '6 Peoples' }
    ]

    TRUSTEE_TYPE_LIST: any = [
        { id: 1, value: 'Indivisual Trustee' },
        { id: 2, value: 'Company Trustee' }
    ]

    TITLE_LIST: any = [
        { id: 'Mr', value: 'Mr.' },
        { id: 'Mrs', value: 'Mrs.' },
        { id: 'Miss', value: 'Miss.' },
        { id: 'Ms', value: 'Ms.' },
        { id: 'Dr', value: 'Dr.' },
        { id: 'Sr', value: 'Sr.' }
    ]

    CITIZENSHIP_LIST = [
        { id: "AUSTRALIA", value: "AUSTRALIA" },
        { id: "AFGHANISTAN", value: "AFGHANISTAN" },
        { id: "ALAND ISLANDS", value: "ALAND ISLANDS" },
        { id: "ALBANIA", value: "ALBANIA" },
        { id: "ALGERIA", value: "ALGERIA" },
        { id: "AMERICAN SAMOA", value: "AMERICAN SAMOA" },
        { id: "ANDORRA", value: "ANDORRA" },
        { id: "ANGOLA", value: "ANGOLA" },
        { id: "ANGUILLA", value: "ANGUILLA" },
        { id: "ANTARCTICA", value: "ANTARCTICA" },
        { id: "ANTIGUA AND BARBUDA", value: "ANTIGUA AND BARBUDA" },
        { id: "ARGENTINA", value: "ARGENTINA" },
        { id: "ARMENIA", value: "ARMENIA" },
        { id: "ARUBA", value: "ARUBA" },
        { id: "AUSTRIA", value: "AUSTRIA" },
        { id: "AZERBAIJAN", value: "AZERBAIJAN" },
        { id: "BAHAMAS", value: "BAHAMAS" },
        { id: "BAHRAIN", value: "BAHRAIN" },
        { id: "BANGLADESH", value: "BANGLADESH" },
        { id: "BARBADOS", value: "BARBADOS" },
        { id: "BELARUS", value: "BELARUS" },
        { id: "BELGIUM", value: "BELGIUM" },
        { id: "BELIZE", value: "BELIZE" },
        { id: "BENIN", value: "BENIN" },
        { id: "BERMUDA", value: "BERMUDA" },
        { id: "BHUTAN", value: "BHUTAN" },
        { id: "BOLIVIA, PLURINATIONAL STATE OF", value: "BOLIVIA, PLURINATIONAL STATE OF" },
        { id: "BONAIRE, SINT EUSTATIUS AND SABA", value: "BONAIRE, SINT EUSTATIUS AND SABA" },
        { id: "BOSNIA AND HERZEGOVINA", value: "BOSNIA AND HERZEGOVINA" },
        { id: "BOTSWANA", value: "BOTSWANA" },
        { id: "BOUVET ISLAND", value: "BOUVET ISLAND" },
        { id: "BRAZIL", value: "BRAZIL" },
        { id: "BRITISH INDIAN OCEAN TERRITORY", value: "BRITISH INDIAN OCEAN TERRITORY" },
        { id: "BRUNEI DARUSSALAM", value: "BRUNEI DARUSSALAM" },
        { id: "BULGARIA", value: "BULGARIA" },
        { id: "BURKINA FASO", value: "BURKINA FASO" },
        { id: "BURUNDI", value: "BURUNDI" },
        { id: "CAMBODIA", value: "CAMBODIA" },
        { id: "CAMEROON", value: "CAMEROON" },
        { id: "CANADA", value: "CANADA" },
        { id: "CAPE VERDE", value: "CAPE VERDE" },
        { id: "CAYMAN ISLANDS", value: "CAYMAN ISLANDS" },
        { id: "CENTRAL AFRICAN REPUBLIC", value: "CENTRAL AFRICAN REPUBLIC" },
        { id: "CHAD", value: "CHAD" },
        { id: "CHILE", value: "CHILE" },
        { id: "CHINA", value: "CHINA" },
        { id: "CHRISTMAS ISLAND", value: "CHRISTMAS ISLAND" },
        { id: "COCOS (KEELING) ISLANDS", value: "COCOS (KEELING) ISLANDS" },
        { id: "COLOMBIA", value: "COLOMBIA" },
        { id: "COMOROS", value: "COMOROS" },
        { id: "CONGO", value: "CONGO" },
        { id: "CONGO, THE DEMOCRATIC REPUBLIC OF THE", value: "CONGO, THE DEMOCRATIC REPUBLIC OF THE" },
        { id: "COOK ISLANDS", value: "COOK ISLANDS" },
        { id: "COSTA RICA", value: "COSTA RICA" },
        { id: "COTE D", value: "COTE D'IVOIRE" },
        { id: "CROATIA", value: "CROATIA" },
        { id: "CUBA", value: "CUBA" },
        { id: "CURAÇAO", value: "CURAÇAO" },
        { id: "CYPRUS", value: "CYPRUS" },
        { id: "CZECH REPUBLIC", value: "CZECH REPUBLIC" },
        { id: "DENMARK", value: "DENMARK" },
        { id: "DJIBOUTI", value: "DJIBOUTI" },
        { id: "DOMINICA", value: "DOMINICA" },
        { id: "DOMINICAN REPUBLIC", value: "DOMINICAN REPUBLIC" },
        { id: "ECUADOR", value: "ECUADOR" },
        { id: "EGYPT", value: "EGYPT" },
        { id: "EL SALVADOR", value: "EL SALVADOR" },
        { id: "EQUATORIAL GUINEA", value: "EQUATORIAL GUINEA" },
        { id: "ERITREA", value: "ERITREA" },
        { id: "ESTONIA", value: "ESTONIA" },
        { id: "ETHIOPIA", value: "ETHIOPIA" },
        { id: "FALKLAND ISLANDS (MALVINAS)", value: "FALKLAND ISLANDS (MALVINAS)" },
        { id: "FAROE ISLANDS", value: "FAROE ISLANDS" },
        { id: "FIJI", value: "FIJI" },
        { id: "FINLAND", value: "FINLAND" },
        { id: "FRANCE", value: "FRANCE" },
        { id: "FRENCH GUIANA", value: "FRENCH GUIANA" },
        { id: "FRENCH POLYNESIA", value: "FRENCH POLYNESIA" },
        { id: "FRENCH SOUTHERN TERRITORIES", value: "FRENCH SOUTHERN TERRITORIES" },
        { id: "GABON", value: "GABON" },
        { id: "GAMBIA", value: "GAMBIA" },
        { id: "GEORGIA", value: "GEORGIA" },
        { id: "GERMANY", value: "GERMANY" },
        { id: "GHANA", value: "GHANA" },
        { id: "GIBRALTAR", value: "GIBRALTAR" },
        { id: "GREECE", value: "GREECE" },
        { id: "GREENLAND", value: "GREENLAND" },
        { id: "GRENADA", value: "GRENADA" },
        { id: "GUADELOUPE", value: "GUADELOUPE" },
        { id: "GUAM", value: "GUAM" },
        { id: "GUATEMALA", value: "GUATEMALA" },
        { id: "GUERNSEY", value: "GUERNSEY" },
        { id: "GUINEA", value: "GUINEA" },
        { id: "GUINEA-BISSAU", value: "GUINEA-BISSAU" },
        { id: "GUYANA", value: "GUYANA" },
        { id: "HAITI", value: "HAITI" },
        { id: "HEARD ISLAND AND MCDONALD ISLANDS", value: "HEARD ISLAND AND MCDONALD ISLANDS" },
        { id: "HOLY SEE (VATICAN CITY STATE)", value: "HOLY SEE (VATICAN CITY STATE)" },
        { id: "HONDURAS", value: "HONDURAS" },
        { id: "HONG KONG", value: "HONG KONG" },
        { id: "HUNGARY", value: "HUNGARY" },
        { id: "ICELAND", value: "ICELAND" },
        { id: "INDIA", value: "INDIA" },
        { id: "INDONESIA", value: "INDONESIA" },
        { id: "IRAN, ISLAMIC REPUBLIC OF", value: "IRAN, ISLAMIC REPUBLIC OF" },
        { id: "IRAQ", value: "IRAQ" },
        { id: "IRELAND", value: "IRELAND" },
        { id: "ISLE OF MAN", value: "ISLE OF MAN" },
        { id: "ISRAEL", value: "ISRAEL" },
        { id: "ITALY", value: "ITALY" },
        { id: "JAMAICA", value: "JAMAICA" },
        { id: "JAPAN", value: "JAPAN" },
        { id: "JERSEY", value: "JERSEY" },
        { id: "JORDAN", value: "JORDAN" },
        { id: "KAZAKHSTAN", value: "KAZAKHSTAN" },
        { id: "KENYA", value: "KENYA" },
        { id: "KIRIBATI", value: "KIRIBATI" },
        { id: "KOREA, DEMOCRATIC PEOPLE", value: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF" },
        { id: "KOREA, REPUBLIC OF", value: "KOREA, REPUBLIC OF" },
        { id: "KUWAIT", value: "KUWAIT" },
        { id: "KYRGYZSTAN", value: "KYRGYZSTAN" },
        { id: "LAO PEOPLE", value: "LAO PEOPLE'S DEMOCRATIC REPUBLIC" },
        { id: "LATVIA", value: "LATVIA" },
        { id: "LEBANON", value: "LEBANON" },
        { id: "LESOTHO", value: "LESOTHO" },
        { id: "LIBERIA", value: "LIBERIA" },
        { id: "LIBYA", value: "LIBYA" },
        { id: "LIECHTENSTEIN", value: "LIECHTENSTEIN" },
        { id: "LITHUANIA", value: "LITHUANIA" },
        { id: "LUXEMBOURG", value: "LUXEMBOURG" },
        { id: "MACAO", value: "MACAO" },
        { id: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF", value: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF" },
        { id: "MADAGASCAR", value: "MADAGASCAR" },
        { id: "MALAWI", value: "MALAWI" },
        { id: "MALAYSIA", value: "MALAYSIA" },
        { id: "MALDIVES", value: "MALDIVES" },
        { id: "MALI", value: "MALI" },
        { id: "MALTA", value: "MALTA" },
        { id: "MARSHALL ISLANDS", value: "MARSHALL ISLANDS" },
        { id: "MARTINIQUE", value: "MARTINIQUE" },
        { id: "MAURITANIA", value: "MAURITANIA" },
        { id: "MAURITIUS", value: "MAURITIUS" },
        { id: "MAYOTTE", value: "MAYOTTE" },
        { id: "MEXICO", value: "MEXICO" },
        { id: "MICRONESIA, FEDERATED STATES OF", value: "MICRONESIA, FEDERATED STATES OF" },
        { id: "MOLDOVA, REPUBLIC OF", value: "MOLDOVA, REPUBLIC OF" },
        { id: "MONACO", value: "MONACO" },
        { id: "MONGOLIA", value: "MONGOLIA" },
        { id: "MONTENEGRO", value: "MONTENEGRO" },
        { id: "MONTSERRAT", value: "MONTSERRAT" },
        { id: "MOROCCO", value: "MOROCCO" },
        { id: "MOZAMBIQUE", value: "MOZAMBIQUE" },
        { id: "MYANMAR", value: "MYANMAR" },
        { id: "NAMIBIA", value: "NAMIBIA" },
        { id: "NAURU", value: "NAURU" },
        { id: "NEPAL", value: "NEPAL" },
        { id: "NETHERLANDS", value: "NETHERLANDS" },
        { id: "NEW CALEDONIA", value: "NEW CALEDONIA" },
        { id: "NEW ZEALAND", value: "NEW ZEALAND" },
        { id: "NICARAGUA", value: "NICARAGUA" },
        { id: "NIGER", value: "NIGER" },
        { id: "NIGERIA", value: "NIGERIA" },
        { id: "NIUE", value: "NIUE" },
        { id: "NORFOLK ISLAND", value: "NORFOLK ISLAND" },
        { id: "NORTHERN MARIANA ISLANDS", value: "NORTHERN MARIANA ISLANDS" },
        { id: "NORWAY", value: "NORWAY" },
        { id: "OMAN", value: "OMAN" },
        { id: "PAKISTAN", value: "PAKISTAN" },
        { id: "PALAU", value: "PALAU" },
        { id: "PALESTINE, STATE OF", value: "PALESTINE, STATE OF" },
        { id: "PANAMA", value: "PANAMA" },
        { id: "PAPUA NEW GUINEA", value: "PAPUA NEW GUINEA" },
        { id: "PARAGUAY", value: "PARAGUAY" },
        { id: "PERU", value: "PERU" },
        { id: "PHILIPPINES", value: "PHILIPPINES" },
        { id: "PITCAIRN", value: "PITCAIRN" },
        { id: "POLAND", value: "POLAND" },
        { id: "PORTUGAL", value: "PORTUGAL" },
        { id: "PUERTO RICO", value: "PUERTO RICO" },
        { id: "QATAR", value: "QATAR" },
        { id: "ROMANIA", value: "ROMANIA" },
        { id: "RUSSIAN FEDERATION", value: "RUSSIAN FEDERATION" },
        { id: "RWANDA", value: "RWANDA" },
        { id: "SAINT HELENA", value: "SAINT HELENA" },
        { id: "SAINT KITTS AND NEVIS", value: "SAINT KITTS AND NEVIS" },
        { id: "SAINT LUCIA", value: "SAINT LUCIA" },
        { id: "SAINT MARTIN", value: "SAINT MARTIN" },
        { id: "SAINT PIERRE AND MIQUELON", value: "SAINT PIERRE AND MIQUELON" },
        { id: "SAINT VINCENT AND THE GRENADINES", value: "SAINT VINCENT AND THE GRENADINES" },
        { id: "SAMOA", value: "SAMOA" },
        { id: "SAN MARINO", value: "SAN MARINO" },
        { id: "SAO TOME AND PRINCIPE", value: "SAO TOME AND PRINCIPE" },
        { id: "SAUDI ARABIA", value: "SAUDI ARABIA" },
        { id: "SENEGAL", value: "SENEGAL" },
        { id: "SERBIA", value: "SERBIA" },
        { id: "SEYCHELLES", value: "SEYCHELLES" },
        { id: "SIERRA LEONE", value: "SIERRA LEONE" },
        { id: "SINGAPORE", value: "SINGAPORE" },
        { id: "SINT MAARTEN (DUTCH PART)", value: "SINT MAARTEN (DUTCH PART)" },
        { id: "SLOVAKIA", value: "SLOVAKIA" },
        { id: "SLOVENIA", value: "SLOVENIA" },
        { id: "SOLOMON ISLANDS", value: "SOLOMON ISLANDS" },
        { id: "SOMALIA", value: "SOMALIA" },
        { id: "SOUTH AFRICA", value: "SOUTH AFRICA" },
        { id: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS", value: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS" },
        { id: "SOUTH SUDAN", value: "SOUTH SUDAN" },
        { id: "SPAIN", value: "SPAIN" },
        { id: "SRI LANKA", value: "SRI LANKA" },
        { id: "SUDAN", value: "SUDAN" },
        { id: "SURINAME", value: "SURINAME" },
        { id: "SVALBARD AND JAN MAYEN", value: "SVALBARD AND JAN MAYEN" },
        { id: "SWAZILAND", value: "SWAZILAND" },
        { id: "SWEDEN", value: "SWEDEN" },
        { id: "SWITZERLAND", value: "SWITZERLAND" },
        { id: "SYRIAN ARAB REPUBLIC", value: "SYRIAN ARAB REPUBLIC" },
        { id: "TAIWAN, PROVINCE OF CHINA", value: "TAIWAN, PROVINCE OF CHINA" },
        { id: "TAJIKISTAN", value: "TAJIKISTAN" },
        { id: "TANZANIA, UNITED REPUBLIC OF", value: "TANZANIA, UNITED REPUBLIC OF" },
        { id: "THAILAND", value: "THAILAND" },
        { id: "TIMOR-LESTE", value: "TIMOR-LESTE" },
        { id: "TOGO", value: "TOGO" },
        { id: "TOKELAU", value: "TOKELAU" },
        { id: "TONGA", value: "TONGA" },
        { id: "TRINIDAD AND TOBAGO", value: "TRINIDAD AND TOBAGO" },
        { id: "TUNISIA", value: "TUNISIA" },
        { id: "TURKEY", value: "TURKEY" },
        { id: "TURKMENISTAN", value: "TURKMENISTAN" },
        { id: "TURKS AND CAICOS ISLANDS", value: "TURKS AND CAICOS ISLANDS" },
        { id: "TUVALU", value: "TUVALU" },
        { id: "UGANDA", value: "UGANDA" },
        { id: "UKRAINE", value: "UKRAINE" },
        { id: "UNITED ARAB EMIRATES", value: "UNITED ARAB EMIRATES" },
        { id: "UNITED KINGDOM", value: "UNITED KINGDOM" },
        { id: "UNITED STATES", value: "UNITED STATES" },
        { id: "UNITED STATES MINOR OUTLYING ISLANDS", value: "UNITED STATES MINOR OUTLYING ISLANDS" },
        { id: "URUGUAY", value: "URUGUAY" },
        { id: "UZBEKISTAN", value: "UZBEKISTAN" },
        { id: "VANUATU", value: "VANUATU" },
        { id: "VENEZUELA, BOLIVARIAN REPUBLIC OF", value: "VENEZUELA, BOLIVARIAN REPUBLIC OF" },
        { id: "VIET NAM", value: "VIET NAM" },
        { id: "VIRGIN ISLANDS, BRITISH", value: "VIRGIN ISLANDS, BRITISH" },
        { id: "VIRGIN ISLANDS, U.S.", value: "VIRGIN ISLANDS, U.S." },
        { id: "WALLIS AND FUTUNA", value: "WALLIS AND FUTUNA" },
        { id: "WESTERN SAHARA", value: "WESTERN SAHARA" },
        { id: "YEMEN", value: "YEMEN" },
        { id: "ZAMBIA", value: "ZAMBIA" },
        { id: "ZIMBABWE ", value: "ZIMBABWE" }
    ]

    JOB_CATEGORY_LIST = [
        { id: "Accommodation and Food Services", value: "Accommodation and Food Services" },
        { id: "Accountants, Auditors and Company Secretaries", value: "Accountants, Auditors and Company Secretaries" },
        { id: "Administrative and Support Services", value: "Administrative and Support Services" },
        { id: "Advertising, Public Relations and Sales", value: "Advertising, Public Relations and Sales" },
        { id: "Arms or Weapons Manufacture or Distribution", value: "Arms or Weapons Manufacture or Distribution" },
        { id: "Arts and Media Professionals", value: "Arts and Media Professionals" },
        { id: "Arts and Recreation Services", value: "Arts and Recreation Services" },
        { id: "Automotive and Engineering Trades Workers", value: "Automotive and Engineering Trades Workers" },
        { id: "Aviation", value: "Aviation" },
        { id: "Bar or Licensed Club", value: "Bar or Licensed Club" },
        { id: "Betting, Bookmaking, Gambling and Gaming", value: "Betting, Bookmaking, Gambling and Gaming" },
        { id: "Business, Human Resource and Marketing Professionals", value: "Business, Human Resource and Marketing Professionals" },
        { id: "Cafe and Restaurant", value: "Cafe and Restaurant" },
        { id: "Carers and Aides", value: "Carers and Aides" },
        { id: "Charity Community or Social Services", value: "Charity Community or Social Services" },
        { id: "Cleaners and Laundry Workers", value: "Cleaners and Laundry Workers" },
        { id: "Clerical and Administrative Workers", value: "Clerical and Administrative Workers" },
        { id: "Clerical and Office Support Workers", value: "Clerical and Office Support Workers" },
        { id: "Construction", value: "Construction" },
        { id: "Construction and Mining Labourers", value: "Construction and Mining Labourers" },
        { id: "Construction Trades Workers", value: "Construction Trades Workers" },
        { id: "Construction, Distribution and Production ", value: "Construction, Distribution and Production " },
        { id: "Design, Engineering, Science and Transport Professionals", value: "Design, Engineering, Science and Transport Professionals" },
        { id: "Digital Currency Traders", value: "Digital Currency Traders" },
        { id: "Doctor, Veterinarian, Health Professionals", value: "Doctor, Veterinarian, Health Professionals" },
        { id: "Education and Training", value: "Education and Training" },
        { id: "Education Professionals", value: "Education Professionals" },
        { id: "Education, Health and Welfare Services", value: "Education, Health and Welfare Services" },
        { id: "Electricity, Gas, Water and Waste Services", value: "Electricity, Gas, Water and Waste Services" },
        { id: "Electrotechnology and Telecommunications Trades Workers", value: "Electrotechnology and Telecommunications Trades Workers" },
        { id: "Engineering and Science Technicians", value: "Engineering and Science Technicians" },
        { id: "Factory Process Workers", value: "Factory Process Workers" },
        { id: "Farm, Forestry and Garden Workers", value: "Farm, Forestry and Garden Workers" },
        { id: "Farmers and Farm", value: "Farmers and Farm" },
        { id: "Farming and Agriculture", value: "Farming and Agriculture" },
        { id: "Financial and Insurance Services", value: "Financial and Insurance Services" },
        { id: "Financial Brokers and Dealers, and Investment Advisers", value: "Financial Brokers and Dealers, and Investment Advisers" },
        { id: "Food Preparation Assistants", value: "Food Preparation Assistants" },
        { id: "Food Trades Workers", value: "Food Trades Workers" },
        { id: "General Clerical Workers", value: "General Clerical Workers" },
        { id: "Health and Welfare Support Workers", value: "Health and Welfare Support Workers" },
        { id: "Health Care and Social Assistance", value: "Health Care and Social Assistance" },
        { id: "Homemaker", value: "Homemaker" },
        { id: "Hospitality Workers", value: "Hospitality Workers" },
        { id: "Hospitality, Retail and Service", value: "Hospitality, Retail and Service" },
        { id: "Hotel and Motel", value: "Hotel and Motel" },
        { id: "Information &amp; Communication Technology", value: "Information &amp; Communication Technology" },
        { id: "Information &amp; Communication Technology Professionals", value: "Information &amp; Communication Technology Professionals" },
        { id: "Information &amp; Communication Technology Technicians", value: "Information &amp; Communication Technology Technicians" },
        { id: "Information Media and Telecommunications", value: "Information Media and Telecommunications" },
        { id: "Inquiry Clerks and Receptionists", value: "Inquiry Clerks and Receptionists" },
        { id: "Jewel, Gem and Precious Metals", value: "Jewel, Gem and Precious Metals" },
        { id: "Labourers", value: "Labourers" },
        { id: "Legal, Social and Welfare Professionals", value: "Legal, Social and Welfare Professionals" },
        { id: "Machinery", value: "Machinery" },
        { id: "Military enlisted", value: "Military enlisted" },
        { id: "Military officer", value: "Military officer" },
        { id: "Mining, Gas, Oil and Petroleum", value: "Mining, Gas, Oil and Petroleum" },
        { id: "Mobile Plant Operators", value: "Mobile Plant Operators" },
        { id: "Money Exchange or Foreign FX Services", value: "Money Exchange or Foreign FX Services" },
        { id: "Numerical Clerks", value: "Numerical Clerks" },
        { id: "Office Managers and Program Administrators", value: "Office Managers and Program Administrators" },
        { id: "Other Technicians and Trades Workers", value: "Other Technicians and Trades Workers" },
        { id: "Pawn Brokers", value: "Pawn Brokers" },
        { id: "Personal Assistants and Secretaries", value: "Personal Assistants and Secretaries" },
        { id: "Professional, Scientific and Technical Services", value: "Professional, Scientific and Technical Services" },
        { id: "Protective Service Workers", value: "Protective Service Workers" },
        { id: "Public Administration and Safety", value: "Public Administration and Safety" },
        { id: "Real Estate Agent", value: "Real Estate Agent" },
        { id: "Rental, Hiring and Real Estate Services", value: "Rental, Hiring and Real Estate Services" },
        { id: "Retail Trade", value: "Retail Trade" },
        { id: "Retired", value: "Retired" },
        { id: "Road and Rail Drivers", value: "Road and Rail Drivers" },
        { id: "Sales Assistants and Salespersons", value: "Sales Assistants and Salespersons" },
        { id: "Sales Representatives and Agents", value: "Sales Representatives and Agents" },
        { id: "Sales Support Workers", value: "Sales Support Workers" },
        { id: "Skilled Animal and Horticultural Workers", value: "Skilled Animal and Horticultural Workers" },
        { id: "Sports and Personal Service Workers", value: "Sports and Personal Service Workers" },
        { id: "Storepersons", value: "Storepersons" },
        { id: "Student", value: "Student" },
        { id: "Transport, Postal and Warehousing", value: "Transport, Postal and Warehousing" },
        { id: "Wholesale Trade", value: "Wholesale Trade" }
    ]
}