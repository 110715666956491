import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  constructor(private _httpClient: HttpClient) { }

  /**
   * Upload Image
   * 
   * @param formData 
   */
  uploadImage(formData: any): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl}file/upload`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Delete File
   * 
   * */
  delete(file) {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.apiUrl}file/delete/${file}`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
