<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>TRANSFER FROM OTHER ACCOUNTANT</h1>
            <p>HOW TO TRANSFER</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12" style="text-align: center;">
                <img src="assets/img/take-on-process.png" alt="image">
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3 class="text-color"><i class="bx bxs-badge-check"></i> IS THERE ANY CHARGE? </h3>
                        <h3 style="color: red;font-weight: bold;">NO ITS TOTALLY FREE = NO CHANGE OVER FEES EVER</h3>
                        <p>Do you have an existing SMSF, are you looking for better service at reasonable cost?</p>
                        <p>You are in right place, based on our research <span class="logo-one-digit">1</span> superfund
                            fees is one of the lowest and our
                            services are very prompt and efficient.</p>
                        <p>Many providers charge a FEES to take on new client to recover cost associated to historical
                            data conversion. </p>
                        <p>We do this totally free-of-charge so that you can get on with the real business of managing
                            your SMSF. Moreover, we provide you with a discounted first year fees. </p>
                        <p>We recommend you consider changing to <span class="logo-one-digit">1</span> superfund to
                            experience the premium, prompt and
                            efficient services it has to offer at a fair price. <span class="logo-one-digit">1</span>
                            superfund is a great value for money
                            but frankly unlike many its not the cheapest provider, the cheapest provider often compels
                            you to buy other financial products from them directly or discretely making smsf out of
                            pocket overall .</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> HARD TO FIND TIME</li>
                        </h3>
                        <p>Suer we understand life can be busy , that’s why out expert team is here to lend a hand free
                            of charge . We will do the transfer for you . </p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> IT TAKES TOO LONG </li>
                        </h3>
                        <p>Once you appoint <span class="logo-one-digit">1</span> superfund, the transfer would be
                            initiated within 24 Hours , most provider
                            is professional and will happily send the documents as soon as possible . </p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> I AM TOO EMBARASSED TO BREAK THE NEWS OF MOVING TO MY
                                EXISTING ACCOUNTANT </li>
                        </h3>
                        <p>We understand you been with the provider some time, they provide with you a service even not
                            great served your purpose. Now you want to take a leap over for the betterment of your SMSF
                            and future. But it sometime hard to break the news, but that’s ok everybody understands it,
                            you have full right to experience better service and providing better savings!</p>
                        <p>We will contact your outgoing accountant and explain everything to them with full empathy and
                            obtain all your past data to make the change over easy.</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> I CAN CALL MY ACCOUNTANT TIME TO TIME – WORRIED IF I
                                GET TO <span class="logo-one-digit">1</span> SUPERFUND – I MAY LOSE
                                THAT ADVANTAGE</li>
                        </h3>
                        <p>Even we are online, our team is working for you and your SMSF 24/7; any technical or other
                            question arise in your mind you can just send us an email, your dedicated account manager
                            will get to you within 4 working hours, with detailed direction what to do. While we advise
                            client to communicate through email, as all recorded for your protection, we understand
                            there are time you may not like writing lengthy emails, that’s why we have dedicated call
                            center for, remember! You can call us any time within working hours and clear what ever in
                            your mind. </p>
                        <p>Ha! just be mindful that you cannot ask what you can do with your money because <span
                                class="logo-one-digit">1</span> superfund
                            ethos is “YOUR SUPER YOUR WAY “.</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> STILL HAVE SOME DOUBT – I NEVER DEALTH WITH AN ONLINE
                                COMPANY BEFORE!</li>
                        </h3>
                        <p>We understand where you coming from. We use only ATO registered Tax Agent to lodge the tax
                            return and ASIC registered SMSF Auditor for audit of your SMSF. All work is supervised by
                            full member of leading professional bodies. </p>
                        <p>All our work is insured professional indemnity insurance by leading underwriters, so for any
                            loss you incur due to our negligence you can recover the cost from the insurer. </p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> WHAT IF AFTER MOOVING TO <span
                                    class="logo-one-digit">1</span> SUPERFUND – I CHANGE MY
                                MIND </li>
                        </h3>
                        <p>That’s a great question, if you change your mind after moving to <span
                                class="logo-one-digit">1</span> superfund whatever the
                            reason, we will fully cooperate with you and provide all your data to you and your new
                            provider within 24 hours. </p>
                        <p>Even further we will make a full refund for any fees charged for which even we have done some
                            of the work but the final services are not delivered i.e. audit report not issued and tax
                            return is not lodged .</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> SOUNDS SO GOOD! HOW CAN I APPOINT <span
                                    class="logo-one-digit">1</span> SUPERFUND? </li>
                        </h3>
                        <p>It is so easy to change that you don’t have to do anything . Just sit and relax!
                            We take care of all and everything . </p>
                        <p>To appoint simply or email us at <a style="color: red;"
                                href="mailto:service@1superfund.com.au">service@1superfund.com.au</a></p>
                        <div style="text-align: center;">
                            <a routerLink="/apply-online" class="default-btn"><i class="bx bxs-hot"></i> Simply Apply
                                Online<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>