<div class="header-top">
        <div class="container">
                <div class="row">
                        <div class="col-sm-12">
                                <i class="bx bxs-phone-call"></i>
                                1300 92 01 89
                                &nbsp; | &nbsp; <i class="bx bxs-envelope"></i>
                                service@1superfund.com.au
                        </div>
                </div>
        </div>
</div>

<div class="{{navbarClass}}">
        <div style="padding-left: 15px; padding-right: 15px;">

                <nav class="navbar navbar-expand-md navbar-light">

                        <a class="navbar-brand" routerLink="/">
                                <img src="assets/img/favicon.png" alt="logo" style="width: 50px; height: 50px;">
                                Superfund
                        </a>

                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav">
                                        <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink="/" class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Home</a>
                                        </li> -->
                                        <li class="nav-item"><a href="#" class="nav-link">About Us <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/about" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">About
                                                                        Us</a></li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/why-us" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Why 1
                                                                        Super</a></li>
                                                </ul>
                                        </li>
                                        <li class="nav-item"><a href="#" class="nav-link">New SMSF <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/apply-online" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Setup
                                                                        SMSF</a></li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/setup-smsf-process"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Setup
                                                                        Process</a></li>
                                                </ul>
                                        </li>
                                        <li class="nav-item"><a href="#" class="nav-link">Existing SMSF <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/appoint-us" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Appoint
                                                                        Us</a></li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/take-on-process" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Take
                                                                        On Process</a></li>
                                                </ul>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink="/smsf-special-offer" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Special
                                                        Offer</a>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink="/fees" class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Fees</a>
                                        </li>

                                        <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Pricing</a>
                                        </li> -->

                                        <!-- <li class="nav-item"><a href="#" class="nav-link">Resources <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/inventments" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Investments</a>
                                                        </li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/contributions-rollover"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                        Contributions
                                                                        Rollover
                                                                        Pension
                                                                        Ownership
                                                                        Sole purpose
                                                                        Updates
                                                                </a></li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/smsf-risks" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">SMSF
                                                                        Risks ATO documents</a></li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/resourses-tax" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Resourses
                                                                        Tax</a>
                                                        </li>
                                                </ul>
                                        </li> -->

                                        <li class="nav-item"><a href="#" class="nav-link">Resources <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <!-- <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Setting-up/"
                                                                        target="_blank" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Setting
                                                                        up</a>
                                                        </li> -->
                                                        <ng-container *ngFor="let navigation of navigationList">
                                                                <li class="nav-item" data-toggle="collapse"
                                                                        data-target=".navbar-collapse.show"><a
                                                                                [routerLink]="'/rollover/'+ navigation.url"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">{{navigation.name}}</a>
                                                                        <ul class="dropdown-menu">
                                                                                <ng-container
                                                                                        *ngFor="let subNavigation of navigation.sub">
                                                                                        <li class="nav-item"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show">
                                                                                                <a [routerLink]="'/rollover/' + navigation.url + '/' + subNavigation.url"
                                                                                                        class="nav-link"
                                                                                                        routerLinkActive="active"
                                                                                                        [routerLinkActiveOptions]="{exact: true}">{{subNavigation.name}}</a>
                                                                                        </li>
                                                                                </ng-container>

                                                                        </ul>
                                                                </li>
                                                        </ng-container>

                                                        <!-- <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a href="#"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Contributions
                                                                        and rollovers</a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Contributions-and-rollovers/Contributions-you-can-accept/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Contributions
                                                                                        you can accept</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Contributions-and-rollovers/Contribution-caps/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Contribution
                                                                                        caps</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Contributions-and-rollovers/Rollovers/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Rollovers</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Contributions-and-rollovers/Personal-contributions---deductions/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Personal
                                                                                        contributions - deductions</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Contributions-and-rollovers/SuperStream-rollovers-and-release-authorities-for-SMSFs/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">SuperStream
                                                                                        rollovers and release
                                                                                        authorities for SMSFs</a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a href="#"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Investing</a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Investing/Your-investment-strategy/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Your
                                                                                        investment strategy</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Investing/Sole-purpose-test/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Sole
                                                                                        purpose test</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Investing/Ownership-and-protection-of-assets/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Ownership
                                                                                        and protection of assets</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Investing/Restrictions-on-investments/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Restrictions
                                                                                        on investments</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Investing/Carrying-on-a-business-in-an-SMSF/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Carrying
                                                                                        on a business in an SMSF</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Investing/Tax-on-income/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Tax
                                                                                        on income</a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a href="#"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Paying
                                                                        benefits </a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Paying-benefits/Preservation-of-super/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Preservation
                                                                                        of super</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Paying-benefits/Lump-sum-and-income-stream-(pension)/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Lump
                                                                                        sum and income stream
                                                                                        (pension)</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Paying-benefits/Conditions-of-release/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Conditions
                                                                                        of release</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Paying-benefits/Death-of-a-member/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Death
                                                                                        of a member</a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a href="#"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Winding
                                                                        up</a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Winding-up/Deal-with-members--benefits/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Deal
                                                                                        with members' benefits</a>
                                                                        </li>
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        href="https://www.ato.gov.au/Super/Self-managed-super-funds/Winding-up/Arrange-a-final-audit-and-complete-your-reporting/"
                                                                                        target="_blank" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Arrange
                                                                                        a final audit and complete your
                                                                                        reporting</a>
                                                                        </li>
                                                                </ul>
                                                        </li> -->
                                                </ul>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                                        </li>
                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Blogs</a>
                                        </li>
                                        <!-- <li class="nav-item"><a href="#" class="nav-link">Sports <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Soccer</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Snooker</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Pool</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Basketball</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Darts</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Netball</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Gaming</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Ohter Sports</a></li>
                        </ul>
                    </li> -->
                                        <!-- <li class="nav-item"><a href="#" class="nav-link">Features <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Scheduling</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Results & Statistics</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Team & Player Registration</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Site Builder</a></li>
                        </ul>
                    </li> -->
                                        <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/"
                            class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                            routerLink="/report" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Report</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                            routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li> -->


                                        <!-- <li class="nav-item"><a href="#" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="#" class="nav-link">With Animation Home <i
                                        class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home One (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/home-2" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Two (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/home-3" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Three (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/home-4" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Four (SaaS Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/home-5" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Five (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/home-6" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Six (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/home-7" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Home Seven (Chatbot)</a></li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Without Animation Home <i
                                        class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/without-animation-home-1" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home One
                                            (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/without-animation-home-2" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Two
                                            (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/without-animation-home-3" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Three (IT Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/without-animation-home-4" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Four (SaaS Startup)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/without-animation-home-5" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Five (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/without-animation-home-6" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Six
                                            (Chatbot)</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/without-animation-home-7" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home
                                            Seven (Chatbot)</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                            routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">About</a></li>

                    <li class="nav-item"><a href="#" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="#" class="nav-link">Features <i
                                        class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/services-1" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Services One</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/services-2" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Services Two</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/services-details" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Features <i
                                        class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/features-1" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Features Style One</a></li>

                                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                            routerLink="/features-2" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Features Style Two</a></li>
                                </ul>
                            </li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/team" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Team</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/log-in" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/sign-up" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/faq" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/error" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>
                        </ul>
                    </li>


                    <li class="nav-item">
                        <a href="#" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/blog-1" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/blog-2" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/blog-details" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                        </ul>
                    </li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                            routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->

                                </ul>

                                <div class="others-options">
                                        <!-- <a routerLink="/apply-online" class="default-btn"
                                                style="padding: 5px; font-size: 13px;">Setup
                                                SMSF<span></span></a>
                                        &nbsp;
                                        <a routerLink="/appoint-us" class="optional-btn"
                                                style="padding: 5px; font-size: 13px;">Transfer
                                                SMSF<span></span></a>
                                        &nbsp;
                                        <a href="http://139.59.24.193/superfund/admin" class="default-btn"
                                                style="padding: 5px; font-size: 13px;" target="_blank">Client
                                                Log In<span></span></a> -->
                                        <a routerLink="/apply-online" class="default-btn" style="font-size: 13px;"><i
                                                        class="bx bxs-hot"></i>Setup
                                                SMSF<span></span></a>
                                        <a routerLink="/appoint-us" class="optional-btn" style="font-size: 13px;"><i
                                                        class="bx bxs-hot"></i>Transfer
                                                SMSF<span></span></a>
                                        &nbsp;
                                        <a [href]="environment.baseUrl+'admin'" class="default-btn"
                                                style="font-size: 13px;" target="_blank"><i
                                                        class="bx bx-log-in"></i>Client
                                                Log In<span></span></a>
                                </div>
                        </div>
                </nav>
        </div>
</div>