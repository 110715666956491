<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>
                    <ul class="services-list">
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/fees">Fees</a></li>
                        <li><a [href]="environment.baseUrl+'admin'" target="_blank">Client Login</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Features</h3>
                    <ul class="support-list">
                        <li><a routerLink="/apply-online">Setup New SMSF</a></li>
                        <li><a routerLink="/appoint-us">Appoint Us</a></li>
                        <li><a routerLink="/fees">Annual Compliance</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Features</h3>
                    <ul class="support-list">
                        <li><a routerLink="/fees">Taxation Advice & Strategiest</a></li>
                        <li><a routerLink="/fees">Pension Commencement</a></li>
                        <li><a routerLink="/fees">Fund Structure Changes</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Head Office: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank"> Plaza Building
                                87-95 Pitt St Sydney NSW 2000 Australia</a>
                        </li>
                        <li>Email: <a href="mailto:service@1superfund.com.au">service@1superfund.com.au</a></li>
                        <li>Phone: <a href="tel:1300920189">1300 92 01 89</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/people/1-Superfund/100087059424537/" target="_blank"><i
                                    class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/1-superfund/" target="_blank"><i
                                    class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.youtube.com/@1advisorygroup" target="_blank"><i
                                    class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.instagram.com/1advisorygroup/" target="_blank"><i
                                    class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>
                Copyright ©{{currentYear}} 1 Advisory Pty Ltd ABN 32644784690 . All rights reserved
                <!-- <a href="#" target="_blank">Terms & Conditions</a> &nbsp;
                <a href="#" target="_blank">Privacy Policy</a> </p> -->
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>