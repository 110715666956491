<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="saas-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <img src="assets/img/saas-shape/arrow.png" class="wow fadeInDown" data-wow-delay="0.6s"
                                alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow fadeInUp" data-wow-delay="0.6s"
                                alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow fadeInLeft" data-wow-delay="0.6s"
                                alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow zoomIn" data-wow-delay="0.6s"
                                alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow bounceIn" data-wow-delay="0.6s"
                                alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png" class="wow fadeInDown"
                                data-wow-delay="0.6s" alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow zoomIn" data-wow-delay="0.6s"
                                alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow fadeInUp" data-wow-delay="0.6s"
                                alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow rotateIn" data-wow-delay="0.6s"
                                alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow fadeInUp" data-wow-delay="0.6s"
                                alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow zoomIn" data-wow-delay="0.6s"
                                alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow zoomIn" data-wow-delay="0.6s"
                                alt="monitor">
                            <img src="assets/img/saas-shape/main-image.png" class="wow zoomIn" data-wow-delay="0.6s"
                                alt="main-image.png">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>Manage all your Self Managed Superfund ( SMSF ) needs @ <span
                                    style="color: red;">1</span>
                                superfund</h1>
                            <p>We are a fixed price, full pledge SMSF accounting service provider . The low cost model
                                allows you to keep most of the nest egg for your own retirement </p>
                            <div style="visibility: hidden;">
                                <span>CHEAP SMSF AUDIT</span>
                                <span>CHEAP SMSF ACCOUNTING</span>
                                <span>CHEAP SMSF ADMINISTRATION</span>
                                <a href="www.ato.gov.au/">Link 1</a>
                                <a href="www.tpb.gov.au">Link 1</a>
                                <a href="asic.gov.au/">Link 1</a>
                                <a href="abr.business.gov.au/">Link 1</a>
                                <a href="www.accc.gov.au/">Link 1</a>
                                <a href="www.1companies.com.au">Link 1</a>
                                <a href="www.1accountants.com.au">Link 1</a>
                                <a href="www.1accountants.co.uk">Link 1</a>
                                <a href="www.1advisory.com.au">Link 1</a>
                                <a href="www.1accountants.co.uk">Link 1</a>
                                <a href="www.1SMSFAUDIT.com.au">Link 1</a>
                            </div>
                            <div class="banner-btn">
                                <div class="d-flex">
                                    <a routerLink="/contact" class="default-btn" style="color:white;"><i
                                            class="bx bxs-hot"></i> Get Started
                                        <span></span></a>
                                    <a href="assets/video/about.mp4" class="video-btn popup-youtube"> <i
                                            class='bx bxs-right-arrow'></i> Watch Video
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-rotate rotateme">
        <img src="assets/img/saas-shape/shape-rotate.png" alt="img">
    </div>
    <div id="particles-js"></div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2>Our amazing features</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".1s">
                    <i class='bx bx-cog bg-13c4a1'></i>
                    <a href="https://www.ato.gov.au/" target="_blank">
                        <h3>Registered tax agent </h3>
                    </a>
                    <p><span class="logo-one-digit">1</span> Super uses Australia based ATO registered tax agents to
                        lodge your tax return .</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".2s">
                    <i class='bx bxs-check-shield bg-6610f2'></i>
                    <a href="https://www.asic.gov.au/" target="_blank">
                        <h3>Registered SMSF Auditor </h3>
                    </a>
                    <p><span class="logo-one-digit">1</span> Super uses Australia based ASIC registered auditor to get
                        your smsf audited for ATO compliance
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".3s">
                    <i class='bx bx-timer bg-ffb700'></i>
                    <a [href]="environment.baseUrl+'admin'" target="_blank">
                        <h3>Time Saving</h3>
                    </a>
                    <p>Our advanced technology makes the process quicker and saves you valuable time </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-badge-check bg-fc3549'></i>
                    <a routerLink="/fees">
                        <h3>Fixed Price Model </h3>
                    </a>
                    <p>Our fixed price model irrespective of type of investment make you save for your retirement.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".5s">
                    <i class='bx bxs-lock-open bg-00d280'></i>
                    <a [href]="environment.baseUrl+'admin'" target="_blank">
                        <h3>Quick Access</h3>
                    </a>
                    <p>You can access you data any time and contract us 24/7 </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bxs-droplet-half bg-ff612f'></i>
                    <a [href]="environment.baseUrl+'admin'" target="_blank">
                        <h3>Drag and Drop</h3>
                    </a>
                    <p>You can contact us via portal , email , post or our Australia based customer center</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why Choose Us To Watch This Video Know More</h2>
            <br>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.png" class="main-image" alt="image">
            <a href="assets/video/about.mp4" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>General Advice <span>Warning</span></h2>
                    <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne"
                                    aria-expanded="false" aria-controls="collapseOne">
                                    Setup of New SMSF <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                    <span class="logo-one-digit">1</span> superfund does not advice or recommend whether
                                    setting up a superfund is right or
                                    beneficial for you in your circumstances. Trustees need to make their own research
                                    whether it is right for them and if required contact a licensed financial planner.
                                    However once decided by trustees, <span class="logo-one-digit">1</span> superfund
                                    can set up the superfund which is of
                                    clerical and technical nature with standard off the shelf trust deed and help you
                                    with the accounting, tax return and audit compliance of your superfund. We do not
                                    advice where you can invest your fund or no invest your fund, you can do your own
                                    research or contact a financial planner if you need assistance.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    No Advice Express or Implied <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                    The contents of this website are of a general nature only and have not been prepared
                                    to take into account any particular investor's objectives, financial situation or
                                    particular needs. <span class="logo-one-digit">1</span> superfund does not provide
                                    financial product advice or recommend
                                    any financial products either expressly or implied. <span
                                        class="logo-one-digit">1</span> superfund expressly states
                                    that it does not recommend, represent as suitable, or endorse any financial product.
                                    This applies equally to those financial products which are established for your SMSF
                                    when you become a client of <span class="logo-one-digit">1</span> superfund.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx"
                                    href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Seek Professional Advice from a Financial Adviser <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                                data-parent="#accordionEx">
                                <div class="card-body">
                                     <span class="logo-one-digit">1</span> superfund is a no advice model and does not
                                    provide financial, legal or tax
                                    advice to clients. We recommend that you seek appropriate professional advice in
                                    view of your personal circumstances. A licensed financial adviser will consider your
                                    personal situation and make a recommendation suitable to your financial needs. All
                                    information detailed in the message is purely factual and is general in nature.
                                    Accordingly, you should not rely on it. It should always be remembered that Trustees
                                    are legally responsible for all the decisions made even if you obtain advice from a
                                    Financial Planner. Whilst a Financial Professional can provide advice and assistance
                                    you are ultimately responsible for the Fund.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area pb-100 pt-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Lets get on board to experience <span class="logo-one-digit">1</span> superfund premium service </p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>