<mat-toolbar class="mat-primary m-0">
  Company Details
  <span class="fill-remaining-space"></span>
  <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<form mat-dialog-content id="contactForm">
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Do you have an Existing Company to act as the Corporate Trustee</mat-label>
        <mat-select [(ngModel)]="formModel.doYouHaveAnExistingCompanyToActAsTheCorporateTrustee"
          [ngModelOptions]="{standalone: true}" required>
          <mat-option value="no">No</mat-option>
          <mat-option value="yes">Yes</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Do you want to establish a Company now?</mat-label>
        <mat-select [(ngModel)]="formModel.doYouWantToEstablishACompanyNow" [ngModelOptions]="{standalone: true}"
          required>
          <mat-option value="no">No</mat-option>
          <mat-option value="yes">Yes</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label> Is the sole purpose of the Company to act as the Trustee of the SMSF?</mat-label>
        <mat-select [(ngModel)]="formModel.isTheSolePurposeOfTheCompanyToActAsTheTrusteeOfTheSMSF"
          [ngModelOptions]="{standalone: true}" required>
          <mat-option value="no">No</mat-option>
          <mat-option value="yes">Yes</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Preferred Company Name</mat-label>
        <input matInput type="text" [(ngModel)]="formModel.preferredCompanyName" [ngModelOptions]="{standalone: true}"
          placeholder="Enter Preferred Company Name" [required]="formModel.doYouWantToEstablishACompanyNow === 'yes'">
        <mat-error>Preferred Company Name is required</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<mat-dialog-actions align="center">
  <button type="button" class="default-btn" [disabled]="isValid()" (click)="
                  submit()"><i class='bx bxs-paper-plane'></i>
    Submit
  </button>
</mat-dialog-actions>