<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>SMSF RISKS</h1>
            <p>SMSF Risks</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image" style="text-align: center;">
                        <img src="assets/img/smsf-risks.png" alt="image">
                    </div>
                    <div class="article-content">
                        <p>It is important to understand that whilst there are many benefits to setting up an SMSF with
                            <span class="logo-one-digit">1</span> SUPERFUND, consideration must also be given to the
                            risks associated with setting up an
                            SMSF and these risks are briefly summarised below:
                        </p>
                        <h3 class="text-color"><span class="logo-one-digit">1</span> SUPERFUND is a No Advice Model</h3>
                        <p><span class="logo-one-digit">1</span> SUPERFUND is a no advice model and does not provide
                            financial advice or recommend if an
                            SMSF is appropriate for your particular objectives, financial situation or particular needs.
                            We recommend that you seek professional advice from a financial adviser before making any
                            decision to establish an SMSF with <span class="logo-one-digit">1</span> SUPERFUND or to
                            purchase any financial product referred
                            to on this website. A licensed financial adviser will consider your personal situation and
                            make a recommendation suitable to your particular financial needs. Importantly however using
                            a financial advisor is optional and it is totally your decision if you wish to appoint one.
                        </p>
                        <p>It should always be remembered that if you setup an SMSF with <span
                                class="logo-one-digit">1</span> SUPERFUND, you will be the
                            Trustee of the SMSF. Therefore, you are legally responsible for all the decisions made even
                            if you obtain advice from a Financial Planner. Whilst a Financial Professional can provide
                            advice and assistance you are ultimately responsible for the Fund. Always remember that 1
                            SUPERFUND cannot advise you about which super fund best suits you or which investments
                            should be in your Fund. Importantly you should consider all your super options before making
                            a decision about managing your own super.</p>
                        <h3 class="text-color">Trustee Time and Skills required to run an SMSF</h3>
                        <p>1. Am I a confident and knowledgeable investor?</p>
                        <p>2. Will an SMSF do as well as or better than other super funds after I pay all the costs?</p>
                        <br>
                        <p>If you are not confident you can get a better result by managing your own super, you may be
                            better off leaving your super to be managed by super professionals.</p>
                        <h3 class="text-color">Minimum Setup Balance and SMSF Fees</h3>
                        <p>You need to ensure you have enough super savings to make your Fund viable. This is based on
                            the balance in your SMSF and the costs to run the Fund. With <span
                                class="logo-one-digit">1</span> SUPERFUND there is no setup
                            fee and the ongoing fees are detailed in the Fees section of our website. You will also need
                            to consider additional fees such as brokerage fees if you intend to trade shares and the
                            annual ATO supervisory levy (currently $259) for example.</p>
                        <p>Whilst there is a general consensus that you need $200,000 to operate an SMSF this may not be
                            so given our low fee structure. However whilst there is no legislative minimum balance
                            required to setup an SMSF it is important you compare your current super fees to the fees to
                            operate an SMSF to determine if an SMSF is right for you. Ultimately you can establish an
                            SMSF with any amount desired. Consideration should also be given to new low cost super
                            offerings under <span class="logo-one-digit">1</span> Super which may better suit your
                            Superannuation requirements from an
                            investment and fee perspective. You should obtain your own independent financial and
                            taxation advice about whether you should set up a SMSF.
                        </p>
                        <h3 class="text-color">Investment Risk</h3>
                        <p>The Trustees control the SMSF and make all the Investment decisions for the SMSF. All
                            financial decisions carry risk, so it is important to think carefully about how you choose
                            your investment options to balance the level of risk against the level of financial return.
                            You also need to be sure your super investments are legal and comply with the permitted
                            investments under the <span class="logo-one-digit">1</span> SUPERFUND Platform detailed
                            under the Investment section of our
                            website.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>