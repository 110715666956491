import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { BlogService } from '../../services/blog.service';
import { MetaService } from '../../services/meta.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-blog-grid',
  templateUrl: './blog-grid.component.html',
  styleUrls: ['./blog-grid.component.scss']
})
export class BlogGridComponent implements OnInit {

  pagination: any;
  pageSizeOptions = [12, 18, 24];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent;
  environment: any = environment;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pagination.perPage = e.pageSize;
    this.pagination.currentPage = e.pageIndex + 1;
    this.getDataWithPagination();
  }

  constructor(
    private _blogService: BlogService,
    public _utilityService: UtilityService,
    private titleService: Title,
    private metaService: MetaService,
    private _activatedRoute: ActivatedRoute
  ) {
    this.metaService.createCanonicalURL();
    this.titleService.setTitle(`Blogs | ${environment.projectName}`);
    this.pagination = _utilityService.pagination;
    this.pagination.perPage = 12;
  }

  ngOnInit() {
    this.getDataWithPagination();
  }

  getDataWithPagination() {
    let filter: any = null;
    if (this._activatedRoute.snapshot.params.url) {
      filter = { category: this._activatedRoute.snapshot.params.url };
    }
    let requestBody = {
      pagination: this.pagination,
      filter: filter
    };
    this._blogService.getDataListWithPagination(requestBody).then((response: any) => {
      this.pagination = response.data;
    })
  }

}
