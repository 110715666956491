import { Component, OnInit, ViewChild } from '@angular/core';
import { NgImageSliderComponent } from 'ng-image-slider';
import * as $ from "jquery";
import { OfferPopupComponent } from '../../popup/offer-popup/offer-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment.prod';
import { Title } from '@angular/platform-browser';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.scss']
})
export class HomeFourComponent implements OnInit {

  images: any = [
    { thumbImage: 'assets/img/full-page-slider/slider1.jpg', title: 'Hummingbirds are amazing creatures' },
    { thumbImage: 'assets/img/full-page-slider/slider2.jpg', title: 'Hummingbirds are amazing creatures' },
    { thumbImage: 'assets/img/full-page-slider/slider3.jpg', title: 'Hummingbirds are amazing creatures' }];

  @ViewChild('nav', { static: true }) slider: NgImageSliderComponent;
  imageSliderHeight = '700px';
  environment = environment;

  constructor(
    public _matDialog: MatDialog,
    private titleService: Title,
    private metaService: MetaService
  ) {
    this.metaService.createCanonicalURL();
    this.titleService.setTitle(`1superfund | ${environment.projectName}`);
  }

  ngOnInit() {
    this.openDialog();

    // setInterval(() => {
    //   this.openDialog();
    // }, 5000);
  }

  openDialog(): void {
    const dialogRef = this._matDialog.open(OfferPopupComponent, {
      width: '635px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
