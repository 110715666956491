<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Apply Online - To Establish New SMSF</h1>
            <p>Or just email a request with your photo ID to service@1superfund.com.au</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100" style="background-color: whitesmoke;">
    <div class="container">
        <div>
            <div class="row">
                <div class="col-lg-1 col-md-1"></div>

                <div class="col-lg-10 col-md-10">
                    <div class="contact-form contact-inner" style=" border-radius: 10px; padding: 50px;">
                        <form id="contactForm" [formGroup]="formGroup" #f="ngForm" (ngSubmit)="submit()">

                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Email *</mat-label>
                                            <input matInput type="email" placeholder="Enter email address"
                                                formControlName="email">
                                            <mat-error
                                                *ngIf="formGroup.controls.email.invalid && (formGroup.controls.email.dirty || formGroup.controls.email.touched)">
                                                <ng-container *ngIf="formGroup.controls.email.errors.required">
                                                    Email is required.
                                                </ng-container>
                                                <ng-container *ngIf="formGroup.controls.email.errors.email">
                                                    Email must be a valid email address
                                                </ng-container>
                                            </mat-error>
                                            <mat-icon matSuffix>email</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Mobile *</mat-label>
                                        <input matInput type="number" placeholder="Enter mobile number"
                                            formControlName="mobile">
                                        <mat-error
                                            *ngIf="formGroup.controls.mobile.invalid && (formGroup.controls.mobile.dirty || formGroup.controls.mobile.touched)">
                                            <ng-container *ngIf="formGroup.controls.mobile.errors.required">
                                                Mobile is required.
                                            </ng-container>
                                            <ng-container
                                                *ngIf="formGroup.controls.mobile.errors.minlength || formGroup.controls.mobile.errors.maxlength">
                                                Mobile must be 10 digits
                                            </ng-container>
                                        </mat-error>
                                        <mat-icon matSuffix>phone</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                            <h5>Residential Address</h5>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Address</mat-label>
                                        <input matInput type="text" formControlName="residentialAddress"
                                            placeholder="Enter address" required>
                                        <mat-error>Address is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Suburb</mat-label>
                                        <input matInput type="text" formControlName="residentialAddressSuburb"
                                            placeholder="Enter suburb" required>
                                        <mat-error>Suburb is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select matInput formControlName="residentialAddressState"
                                            placeholder="Choose state" required>
                                            <mat-option *ngFor="let element of _constantService.STATE_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>State is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Postcode</mat-label>
                                        <input matInput type="number" formControlName="residentialAddressPostCode"
                                            placeholder="Enter postcode" required>
                                        <mat-error>Postcode is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <h5>Postal Address</h5>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Address</mat-label>
                                        <input matInput type="text" formControlName="postalAddress"
                                            placeholder="Enter address" required>
                                        <mat-error>Address is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Suburb</mat-label>
                                        <input matInput type="text" formControlName="postalAddressSuburb"
                                            placeholder="Enter suburb" required>
                                        <mat-error>Suburb is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select matInput formControlName="postalAddressState"
                                            placeholder="Choose state" required>
                                            <mat-option *ngFor="let element of _constantService.STATE_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>State is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Postcode</mat-label>
                                        <input matInput type="number" formControlName="postalAddressPostCode"
                                            placeholder="Enter postcode" required>
                                        <mat-error>Postcode is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>How many people in your SMSF</mat-label>
                                        <mat-select matInput formControlName="numberOfPeople"
                                            placeholder="Choose How many people in your SMSF" required
                                            (selectionChange)="onSelectionHowManyPeople($event.value)"
                                            [(ngModel)]="formModel.numberOfPeople">
                                            <mat-option *ngFor="let element of _constantService.PERSON_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>How many people in your SMSF is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Trustee Type</mat-label>
                                        <mat-select matInput formControlName="trusteeType"
                                            placeholder="Choose Trustee Type" required
                                            (selectionChange)="onSelectionTrusteeType($event.value)"
                                            [(ngModel)]="formModel.trusteeType">
                                            <mat-option *ngFor="let element of _constantService.TRUSTEE_TYPE_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>Trustee Type is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6" *ngIf="formModel.trusteeType === 2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Company Details</mat-label>
                                        <input matInput type="text" formControlName="companyName"
                                            placeholder="Enter Company Details" (click)="getCompanyDetails()" readonly>
                                        <mat-error>Company Details is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6"
                                    *ngIf="formGroup.get('numberOfPeople').value === 1 || formGroup.get('numberOfPeople').value === 2 || formGroup.get('numberOfPeople').value === 3 || formGroup.get('numberOfPeople').value === 4 || formGroup.get('numberOfPeople').value === 5 || formGroup.get('numberOfPeople').value === 6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 1 Details'
                                            :'Director 1
                                            Details'}}</mat-label>
                                        <input matInput type="text" formControlName="trusteeOneName"
                                            placeholder="Enter Trustee 1 Details"
                                            (click)="getTrusteeDetails('trusteeOneDetails',trusteeOneDetails)" readonly>
                                        <mat-error>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 1 Details'
                                            :'Director 1
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6"
                                    *ngIf="formGroup.get('numberOfPeople').value === 2 || formGroup.get('numberOfPeople').value === 3 || formGroup.get('numberOfPeople').value === 4 || formGroup.get('numberOfPeople').value === 5 || formGroup.get('numberOfPeople').value === 6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 2 Details'
                                            :'Director 2
                                            Details'}}</mat-label>
                                        <input matInput type="text" formControlName="trusteeTwoName"
                                            placeholder="Enter Trustee 2 Details"
                                            (click)="getTrusteeDetails('trusteeTwoDetails',trusteeTwoDetails)" readonly>
                                        <mat-error>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 2 Details'
                                            :'Director 2
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6"
                                    *ngIf="formGroup.get('numberOfPeople').value === 3 || formGroup.get('numberOfPeople').value === 4 || formGroup.get('numberOfPeople').value === 5 || formGroup.get('numberOfPeople').value === 6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 3 Details'
                                            :'Director 3
                                            Details'}}</mat-label>
                                        <input matInput type="text" formControlName="trusteeThreeName"
                                            placeholder="Enter Trustee 3 Details"
                                            (click)="getTrusteeDetails('trusteeThreeDetails',trusteeThreeDetails)"
                                            readonly>
                                        <mat-error>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 3 Details'
                                            :'Director 3
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-6" 
                                    *ngIf="formGroup.get('numberOfPeople').value === 4 || formGroup.get('numberOfPeople').value === 5 || formGroup.get('numberOfPeople').value === 6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 4 Details'
                                            :'Director 4
                                            Details'}}</mat-label>
                                        <input matInput type="text" formControlName="trusteeFourName"
                                            placeholder="Enter Trustee 4 Details"
                                            (click)="getTrusteeDetails('trusteeFourDetails',trusteeFourDetails)"
                                            readonly>
                                        <mat-error>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 4 Details'
                                            :'Director 4
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6" 
                                *ngIf="formGroup.get('numberOfPeople').value === 5 || formGroup.get('numberOfPeople').value === 6">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 5 Details'
                                        :'Director 5
                                        Details'}}</mat-label>
                                    <input matInput type="text" formControlName="trusteeFiveName"
                                        placeholder="Enter Trustee 5 Details"
                                        (click)="getTrusteeDetails('trusteeFiveDetails',trusteeFiveDetails)"
                                        readonly>
                                    <mat-error>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 5 Details'
                                        :'Director 5
                                        Details'}} is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-md-6" 
                                    *ngIf="formGroup.get('numberOfPeople').value === 6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 6 Details'
                                            :'Director 6
                                            Details'}}</mat-label>
                                        <input matInput type="text" formControlName="trusteeSixName"
                                            placeholder="Enter Trustee 6 Details"
                                            (click)="getTrusteeDetails('trusteeSixDetails',trusteeSixDetails)"
                                            readonly>
                                        <mat-error>{{formGroup.get('trusteeType').value === 1 ? 'Trustee 6 Details'
                                            :'Director 6
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Preferred SMSF Name</mat-label>
                                        <input matInput type="text" formControlName="preferredSMSFName"
                                            placeholder="Enter Preferred SMSF Name" required>
                                        <mat-error>Preferred SMSF Name is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="">Confirm you have read our Disclosures <span>*</span></label>
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures1.invalid && (disclosures1.dirty || disclosures1.touched)">
                                            <div *ngIf="disclosures1.errors.required" class="error">
                                                Please tick and confirm that you have read our Disclosures .
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures1="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="disclosure1"
                                                [ngModelOptions]="{standalone: true}">
                                            I acknowledge and agree that I have read the Disclosures and agree to the
                                            terms and conditions contained in the Disclosures.
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures2.invalid && (disclosures2.dirty || disclosures2.touched)">
                                            <div *ngIf="disclosures2.errors.required" class="error">
                                                Please tick and confirm that you are authorised to make this
                                                application.
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures2="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="disclosure2"
                                                [ngModelOptions]="{standalone: true}">
                                            I agree that I am the Trustee or am authorised by the Trustee to make this
                                            application.
                                        </p>
                                    </div>

                                    <!-- <ngx-recaptcha2 #captchaElem [siteKey]="'6LdCZzYgAAAAAEo6lxKIi9PZasCbdaY32a1BHYth'"
                                        (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                                        [hl]="lang" [theme]="theme" [type]="type" [(ngModel)]="formGroup.recaptcha"
                                        [ngModelOptions]="{standalone: true}">
                                    </ngx-recaptcha2> -->

                                    <!-- <ngx-recaptcha2 #captchaElem [siteKey]="environment.recaptchaSiteKey"
                                        (success)="handleSuccess($event)" [useGlobalDomain]="false" [size]="size"
                                        [hl]="lang" [theme]="theme" [type]="type">
                                    </ngx-recaptcha2> -->

                                    <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LdCZzYgAAAAAEo6lxKIi9PZasCbdaY32a1BHYth"></re-captcha> -->
                                    <br>
                                    <div class="form-group">
                                        <button type="submit" class="default-btn"
                                            [disabled]="formGroup.invalid || isValid()"><i
                                                class='bx bxs-paper-plane'></i>
                                            Submit
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="alert alert-danger fade show" *ngIf="errorMessage != ''">
                                            {{errorMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="errorMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="alert alert-success fade show" *ngIf="successMessage != ''">
                                            {{successMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="successMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <!-- <form id="contactForm">

                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input matInput type="email" [(ngModel)]="formModel.email"
                                                [ngModelOptions]="{standalone: true}" [email]="true" #email="ngModel"
                                                placeholder="Enter email" required>
                                            <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
                                                <span *ngIf="email.errors.required">Email is required.</span>
                                                <span *ngIf="email.errors.email">Enter a valid email address.</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Phone Number</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.mobileNumber"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter phone number"
                                            required (keypress)="keyPress($event)" minlength=10 maxlength=10>
                                        <mat-error>Phone Number is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <h5>Residential Address</h5>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Address</mat-label>
                                        <input matInput type="text"
                                            [(ngModel)]="formModel.address.residentialAddress.address"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter address" required>
                                        <mat-error>Address is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Suburb</mat-label>
                                        <input matInput type="text"
                                            [(ngModel)]="formModel.address.residentialAddress.suburb"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter suburb" required>
                                        <mat-error>Suburb is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select matInput [(ngModel)]="formModel.address.residentialAddress.state"
                                            [ngModelOptions]="{standalone: true}" placeholder="Choose state" required>
                                            <mat-option *ngFor="let element of _constantService.STATE_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>State is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Postcode</mat-label>
                                        <input matInput type="number"
                                            [(ngModel)]="formModel.address.residentialAddress.postCode"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter postcode" required>
                                        <mat-error>Postcode is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <h5>Postal Address</h5>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Address</mat-label>
                                        <input matInput type="text"
                                            [(ngModel)]="formModel.address.postalAddress.address"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter address" required>
                                        <mat-error>Address is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Suburb</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.address.postalAddress.suburb"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter suburb" required>
                                        <mat-error>Suburb is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>State</mat-label>
                                        <mat-select matInput [(ngModel)]="formModel.address.postalAddress.state"
                                            [ngModelOptions]="{standalone: true}" placeholder="Choose state" required>
                                            <mat-option *ngFor="let element of _constantService.STATE_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>State is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Postcode</mat-label>
                                        <input matInput type="number"
                                            [(ngModel)]="formModel.address.postalAddress.postCode"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter postcode" required>
                                        <mat-error>Postcode is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>How many people in your SMSF</mat-label>
                                        <mat-select matInput [(ngModel)]="formModel.numberOfPeople"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Choose How many people in your SMSF" required
                                            (selectionChange)="onSelectionHowManyPeople($event.value)">
                                            <mat-option *ngFor="let element of _constantService.PERSON_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>How many people in your SMSF is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Trustee Type</mat-label>
                                        <mat-select matInput [(ngModel)]="formModel.trusteeType"
                                            [ngModelOptions]="{standalone: true}" placeholder="Choose Trustee Type"
                                            required (selectionChange)="onSelectionTrusteeType($event.value)">
                                            <mat-option *ngFor="let element of _constantService.TRUSTEE_TYPE_LIST"
                                                [value]="element.id">
                                                {{element.value}}</mat-option>
                                        </mat-select>
                                        <mat-error>Trustee Type is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6" *ngIf="formModel.trusteeType === 2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Company Details</mat-label>
                                        <input matInput type="text" [(ngModel)]="companyName"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter Company Details"
                                            required (click)="getCompanyDetails()" readonly>
                                        <mat-error>Company Details is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6"
                                    *ngIf="formModel.numberOfPeople === 1 || formModel.numberOfPeople === 2 || formModel.numberOfPeople === 3 || formModel.numberOfPeople === 4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formModel.trusteeType === 1 ? 'Trustee 1 Details' :'Director 1
                                            Details'}}</mat-label>
                                        <input matInput type="text" [(ngModel)]="trusteeOneName"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter Trustee 1 Details"
                                            required
                                            (click)="getTrusteeDetails('trusteeOneDetails',formModel.trusteeOneDetails)"
                                            readonly>
                                        <mat-error>{{formModel.trusteeType === 1 ? 'Trustee 1 Details' :'Director 1
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6"
                                    *ngIf="formModel.numberOfPeople === 2 || formModel.numberOfPeople === 3 || formModel.numberOfPeople === 4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formModel.trusteeType === 1 ? 'Trustee 2 Details' :'Director 2
                                            Details'}}</mat-label>
                                        <input matInput type="text" [(ngModel)]="trusteeTwoName"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter Trustee 2 Details"
                                            required
                                            (click)="getTrusteeDetails('trusteeTwoDetails',formModel.trusteeTwoDetails)"
                                            readonly>
                                        <mat-error>{{formModel.trusteeType === 1 ? 'Trustee 2 Details' :'Director 2
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6"
                                    *ngIf="formModel.numberOfPeople === 3 || formModel.numberOfPeople === 4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formModel.trusteeType === 1 ? 'Trustee 3 Details' :'Director 3
                                            Details'}}</mat-label>
                                        <input matInput type="text" [(ngModel)]="trusteeThreeName"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter Trustee 3 Details"
                                            required
                                            (click)="getTrusteeDetails('trusteeThreeDetails',formModel.trusteeThreeDetails)"
                                            readonly>
                                        <mat-error>{{formModel.trusteeType === 1 ? 'Trustee 3 Details' :'Director 3
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-6" *ngIf="formModel.numberOfPeople === 4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{formModel.trusteeType === 1 ? 'Trustee 4 Details' :'Director 4
                                            Details'}}</mat-label>
                                        <input matInput type="text" [(ngModel)]="trusteeFourName"
                                            [ngModelOptions]="{standalone: true}" placeholder="Enter Trustee 4 Details"
                                            required
                                            (click)="getTrusteeDetails('trusteeFourDetails',formModel.trusteeFourDetails)"
                                            readonly>
                                        <mat-error>{{formModel.trusteeType === 1 ? 'Trustee 4 Details' :'Director 4
                                            Details'}} is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Preferred SMSF Name</mat-label>
                                        <input matInput type="text" [(ngModel)]="formModel.preferredSMSFName"
                                            [ngModelOptions]="{standalone: true}"
                                            placeholder="Enter Preferred SMSF Name" required>
                                        <mat-error>Preferred SMSF Name is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="">Confirm you have read our Disclosures <span>*</span></label>
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures1.invalid && (disclosures1.dirty || disclosures1.touched)">
                                            <div *ngIf="disclosures1.errors.required" class="error">
                                                Please tick and confirm that you have read our Disclosures .
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures1="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="formModel.disclosures1"
                                                [ngModelOptions]="{standalone: true}">
                                            I acknowledge and agree that I have read the Disclosures and agree to the
                                            terms and conditions contained in the Disclosures.
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <ng-container
                                            *ngIf="commonErrorMsg && disclosures2.invalid && (disclosures2.dirty || disclosures2.touched)">
                                            <div *ngIf="disclosures2.errors.required" class="error">
                                                Please tick and confirm that you are authorised to make this
                                                application.
                                            </div>
                                        </ng-container>
                                        <p>
                                            <input type="checkbox" #disclosures2="ngModel" required
                                                (click)="commonErrorMsg = true" [(ngModel)]="formModel.disclosures2"
                                                [ngModelOptions]="{standalone: true}">
                                            I agree that I am the Trustee or am authorised by the Trustee to make this
                                            application.
                                        </p>
                                    </div>

                                    <div class="form-group">
                                        <button type="button" class="default-btn" [disabled]="isValid()" (click)="
                                            submit()"><i class='bx bxs-paper-plane'></i>
                                            Submit
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="alert alert-danger fade show" *ngIf="errorMessage != ''">
                                            {{errorMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="errorMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="alert alert-success fade show" *ngIf="successMessage != ''">
                                            {{successMessage}}
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="successMessage = '' ">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>