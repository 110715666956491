<mat-toolbar color="primary">
  <span>Important</span>
  <span class="fill-remaining-space"></span>
  <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<p style="font-weight: bold;">You have selected a Company to act as the Trustee of your SMSF. Before proceeding please
  note that:</p>

<p>Under this option, you can have a Minimum of 1 Member in your SMSF (and a Maximum of 6 Members) , however if you do
  not have a Company already setup, a separate fees applies to setup your SMSF . </p>

<p>NB: The company set up fee is a nonrefundable fee as this fee consists of a fee payable to ASIC. Alternatively, you
  can appoint Individual Trustees for your SMSF, however you must have a Minimum of 2 Individual Trustees.</p>