import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment.prod';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-setup-new-smsf',
  templateUrl: './setup-new-smsf.component.html',
  styleUrls: ['./setup-new-smsf.component.scss']
})
export class SetupNewSmsfComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: MetaService
  ) {
    this.metaService.createCanonicalURL();
    this.titleService.setTitle(`Setup New SMSF | ${environment.projectName}`);
  }

  ngOnInit(): void {
  }

}
