import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { NavigationService } from '../../services/navigation.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;
    environment = environment;
    navigationList: any;

    constructor(
        private router: Router,
        location: Location,
        private _navigationService: NavigationService
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                    if (this.location == '/' || this.location == '/home-2' || this.location == '/home-3' || this.location == '/home-5' || this.location == '/home-6' || this.location == '/home-7' || this.location == '/without-animation-home-1' || this.location == '/without-animation-home-2' || this.location == '/without-animation-home-3' || this.location == '/without-animation-home-5' || this.location == '/without-animation-home-6' || this.location == '/without-animation-home-7') {
                        this.navbarClass = 'navbar-area bg-white';
                    } else if (this.location == '/home-4' || this.location == '/without-animation-home-4') {
                        this.navbarClass = 'navbar-area bg-white';
                    } else {
                        this.navbarClass = 'navbar-area navbar-style-two';
                    }
                }
            });
    }

    ngOnInit() {
        this._navigationService.getDataList().then((response: any) => {
            this.navigationList = response.data;
        })
    }

}