import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offer-popup',
  templateUrl: './offer-popup.component.html',
  styleUrls: ['./offer-popup.component.scss']
})
export class OfferPopupComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<OfferPopupComponent>,
    private _router:Router
  ) {
    matDialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  openFees(){
    this.matDialogRef.close();
    this._router.navigateByUrl('/fees');
  }


}
