<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Blog Details</h1>
            <p>News and Insights</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100" *ngIf="dataObject">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image" style="text-align: center;">
                        <img [src]="_utilityService.getImageUrl(dataObject.image)" [alt]="dataObject.title">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><i class='bx bx-time'></i> <a href="#">{{dataObject.createdAt | date : "MMM, dd
                                        yyyy"}}</a></li>
                                <li><i class='bx bx-user'></i> <a href="#">{{dataObject.author}}</a></li>
                            </ul>
                        </div>
                        <h3>{{dataObject.title}}</h3>
                        <div [innerHTML]="dataObject.description"></div>
                    </div>
                    <hr>
                    <h3 style="font-size: 20px;" class="text-bold">Share On Social : </h3>
                    <div class="single-footer-widget">
                        <ul class="social">
                            <li><a href="https://www.facebook.com/profile.php?id=100087059424537" target="_blank"><i
                                        class="bx bxl-facebook"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/1-superfund/" target="_blank"><i
                                        class="bx bxl-linkedin"></i></a></li>
                            <li><a href="https://www.youtube.com/@1advisorygroup" target="_blank"><i
                                        class="bx bxl-youtube"></i></a></li>
                            <li><a href="https://www.instagram.com/1advisorygroup/" target="_blank"><i
                                        class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="comments-area">
                        <h3 class="comments-title" *ngIf="comments?.length">{{comments.length}} Comments:</h3>
                        <ol class="comment-list">
                            <ng-container *ngFor="let element of comments">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author-image/img1.jpg" class="avatar"
                                                    [alt]="element.name">
                                                <b class="fn">{{element.name}}</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a href="#">
                                                    {{element.createdAt | date : "MMM dd, yyyy aa"}}
                                                </a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>{{element.comment}}</p>
                                        </div>
                                        <!-- <div class="reply">
                                            <a href="#" class="comment-reply-link">Reply</a>
                                        </div> -->
                                    </article>
                                    <ol class="children">
                                        <ng-container *ngFor="let childComment of element.comments">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
                                                            <img src="assets/img/author-image/img2.jpg" class="avatar"
                                                                [alt]="childComment.name">
                                                            <b class="fn">{{childComment.name}}</b>
                                                            <span class="says">says:</span>
                                                        </div>
                                                        <div class="comment-metadata">
                                                            <a href="#">
                                                                {{childComment.createdAt | date : "MMM dd, yyyy aa"}}
                                                            </a>
                                                        </div>
                                                    </footer>
                                                    <div class="comment-content">
                                                        <p>{{childComment.comment}}</p>
                                                    </div>
                                                    <!-- <div class="reply">
                                                        <a href="#" class="comment-reply-link">Reply</a>
                                                    </div> -->
                                                </article>
                                            </li>
                                        </ng-container>
                                    </ol>
                                </li>
                            </ng-container>
                        </ol>
                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>
                            <form class="comment-form" [formGroup]="commentFormGroup">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525"
                                        required="required" formControlName="comment"></textarea>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" name="author" required="required"
                                        formControlName="name">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" name="email" required="required"
                                        formControlName="email">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url" formControlName="website">
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" name="wp-comment-cookies-consent"
                                        id="wp-comment-cookies-consent" formControlName="isSaveDataToBrowser">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this
                                        browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="button" name="submit" id="submit" class="submit" value="Post A Comment"
                                        [disabled]="commentFormGroup.invalid" (click)="submit()">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class='bx bx-search-alt'></i></button>
                        </form>
                    </section>
                    <section class="widget widget_jexsa_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <ng-container *ngFor="let element of popularBlogs">
                            <article class="item">
                                <a [routerLink]="'/blog-details/' + element.url" class="thumb"><span
                                        class="fullimage cover bg1" role="img"
                                        [ngStyle]="{'background' : 'url(' +_utilityService.getImageUrl(element.image)+ ')'}"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">{{element.createdAt | date : "MMM, dd yyyy"}}</time>
                                    <h4 class="title usmall"><a
                                            [routerLink]="'/blog-details/' + element.url">{{element.title}}</a></h4>
                                </div>
                                <div class="clear"></div>
                            </article>
                        </ng-container>
                    </section>
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <ng-container *ngFor="let element of categories">
                                <li><a [routerLink]="'/blog/' + element.url">{{element.name}}</a></li>
                            </ng-container>
                        </ul>
                    </section>
                    <!-- <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>
                        <ul>
                            <li><a href="#">May 2020</a></li>
                            <li><a href="#">April 2020</a></li>
                            <li><a href="#">June 2020</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>
                        <ul>
                            <li><a href="#">Log in</a></li>
                            <li><a href="#">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a href="#">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a href="#">WordPress.org</a></li>
                        </ul>
                    </section> -->
                    <!-- <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">
                            <a href="#">IT <span class="tag-link-count"> (3)</span></a>
                            <a href="#">Jexsa <span class="tag-link-count"> (3)</span></a>
                            <a href="#">Games <span class="tag-link-count"> (2)</span></a>
                            <a href="#">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a href="#">Travel <span class="tag-link-count"> (1)</span></a>
                            <a href="#">Smart <span class="tag-link-count"> (1)</span></a>
                            <a href="#">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a href="#">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section> -->
                </aside>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>