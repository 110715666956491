import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { CompanyDetailsPopupComponent } from '../../popup/company-details-popup/company-details-popup.component';
import { TrusteeDetailsPopupComponent } from '../../popup/trustee-details-popup/trustee-details-popup.component';
import { ConstantService } from '../../services/constant.service';
import { MetaService } from '../../services/meta.service';
import { NewRequestService } from '../../services/new-request.service';
import { ErrorPopupComponent } from './error-popup/error-popup.component';

@Component({
  selector: 'app-apply-online',
  templateUrl: './apply-online.component.html',
  styleUrls: ['./apply-online.component.scss']
})
export class ApplyOnlineComponent implements OnInit {

  size: any = 'Normal';
  lang: any = 'en';
  theme: any = 'Light';
  type: any = 'Image';

  @ViewChild('f', { static: true }) form;
  formGroup: FormGroup;

  formModel: any = {
    email: null,
    mobileNumber: null,
    address: {
      residentialAddress: {
        address: null,
        suburb: null,
        state: null,
        postCode: null
      },
      postalAddress: {
        address: null,
        suburb: null,
        state: null,
        postCode: null
      }
    },
    numberOfPeople: 2,
    trusteeType: 1,
    companyDetails: null,
    trusteeOneDetails: null,
    trusteeTwoDetails: null,
    trusteeThreeDetails: null,
    trusteeFourDetails: null,
    trusteeFiveDetails: null,
    trusteeSixDetails: null,
    preferredSMSFName: null,
    disclosures1: null,
    disclosures2: null
  }

  successMessage: any = "";
  errorMessage: any = "";
  commonErrorMsg: boolean = true;

  trusteeOneName: any = "";
  trusteeTwoName: any = "";
  trusteeThreeName: any = "";
  trusteeFourName: any = "";
  trusteeFiveName: any = "";
  trusteeSixName: any = "";
  

  trusteeOneDetails = null;
  trusteeTwoDetails = null;
  trusteeThreeDetails = null;
  trusteeFourDetails = null;
  trusteeFiveDetails = null;
  trusteeSixDetails = null;

  companyName: any = "";
  companyDetails: any = null;
  disclosure1: any;
  disclosure2: any;
  environment: any = environment;

  constructor(
    private _newRequestService: NewRequestService,
    private _matDialog: MatDialog,
    public _constantService: ConstantService,
    private _formBuilder: FormBuilder,
    private titleService: Title,
    private metaService: MetaService
    ) {
        this.metaService.createCanonicalURL();
    this.titleService.setTitle(`Establish New SMSF | ${environment.projectName}`);
  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      email: [null, Validators.required],
      mobile: [null, Validators.required],
      residentialAddress: [null, Validators.required],
      residentialAddressSuburb: [null, Validators.required],
      residentialAddressState: [null, Validators.required],
      residentialAddressPostCode: [null, Validators.required],
      postalAddress: [null, Validators.required],
      postalAddressSuburb: [null, Validators.required],
      postalAddressState: [null, Validators.required],
      postalAddressPostCode: [null, Validators.required],
      numberOfPeople: [null],
      trusteeType: [null],
      companyName: [null],
      trusteeOneName: [null],
      trusteeTwoName: [null],
      trusteeThreeName: [null],
      trusteeFourName: [null],
      trusteeFiveName: [null],
      trusteeSixName: [null],
      preferredSMSFName: [null]
    });
  }

  submit() {
    let data = this.formGroup.getRawValue();
    let json = {
      email: data.email,
      mobileNumber: data.mobile,
      address: {
        residentialAddress: {
          address: data.residentialAddress,
          suburb: data.residentialAddressSuburb,
          state: data.residentialAddressState,
          postCode: data.residentialAddressPostCode
        },
        postalAddress: {
          address: data.postalAddress,
          suburb: data.postalAddressSuburb,
          state: data.postalAddressState,
          postCode: data.postalAddressPostCode
        }
      },
      numberOfPeople: data.numberOfPeople,
      trusteeType: data.trusteeType,
      companyDetails: this.companyDetails,
      trusteeOneDetails: this.trusteeOneDetails,
      trusteeTwoDetails: this.trusteeTwoDetails,
      trusteeThreeDetails: this.trusteeThreeDetails,
      trusteeFourDetails: this.trusteeFourDetails,
      trusteeFiveDetails: this.trusteeFiveDetails,
      trusteeSixDetails: this.trusteeSixDetails,
      preferredSMSFName: data.preferredSMSFName
    }
    this._newRequestService.add(json).then((response: any) => {
      if (response && response.status === 'OK') {
        //Reset form
        this.form.resetForm();
        this.errorMessage = "";
        this.successMessage = response.message;
        this.companyDetails = null;
        this.trusteeOneDetails = null;
        this.trusteeTwoDetails = null;
        this.trusteeThreeDetails = null;
        this.trusteeFourDetails = null;
        this.trusteeFiveDetails = null;
        this.trusteeSixDetails = null;
        this.commonErrorMsg = false;
        //this.myForm.resetForm();
      } else {
        this.successMessage = "";
        this.errorMessage = response.message;
        this.commonErrorMsg = false;
      }
    }, error => {
      this.successMessage = "";
      this.errorMessage = "Internal Server Error";
    })
  }

  isValid() {
    let isValid = false;
    if (
      !this.disclosure1 || this.disclosure1 === ''
      || !this.disclosure2 || this.disclosure2 === ''
    ) {
      isValid = true;
    }
    if (this.formGroup.controls.numberOfPeople.value) {
      if (this.formGroup.controls.numberOfPeople.value === 1) {
        if (!this.trusteeOneDetails || this.trusteeOneDetails === '') {
          isValid = true;
        }
      } else if (this.formGroup.controls.numberOfPeople.value === 2) {
        if (!this.trusteeOneDetails || this.trusteeOneDetails === '' || !this.trusteeTwoDetails || this.trusteeTwoDetails === '') {
          isValid = true;
        }
      } else if (this.formGroup.controls.numberOfPeople.value === 3) {
        if (!this.trusteeOneDetails || this.trusteeOneDetails === '' || !this.trusteeTwoDetails || this.trusteeTwoDetails === '' || !this.trusteeThreeDetails || this.trusteeThreeDetails === '') {
          isValid = true;
        }
      } else if (this.formGroup.controls.numberOfPeople.value === 4) {
        if (!this.trusteeOneDetails || this.trusteeOneDetails === '' || !this.trusteeTwoDetails || this.trusteeTwoDetails === '' || !this.trusteeThreeDetails || this.trusteeThreeDetails === '' || !this.trusteeFourDetails || this.trusteeFourDetails === '') {
          isValid = true;
        }
      }else if (this.formGroup.controls.numberOfPeople.value === 5) {
        if (!this.trusteeOneDetails || this.trusteeOneDetails === '' || !this.trusteeTwoDetails || this.trusteeTwoDetails === '' || !this.trusteeThreeDetails || this.trusteeThreeDetails === '' || !this.trusteeFourDetails || this.trusteeFourDetails === '' || !this.trusteeFiveDetails || this.trusteeFiveDetails === '') {
          isValid = true;
        }
      }else if (this.formGroup.controls.numberOfPeople.value === 6) {
        if (!this.trusteeOneDetails || this.trusteeOneDetails === '' || !this.trusteeTwoDetails || this.trusteeTwoDetails === '' || !this.trusteeThreeDetails || this.trusteeThreeDetails === '' || !this.trusteeFourDetails || this.trusteeFourDetails === '' || !this.trusteeFiveDetails || this.trusteeFiveDetails === '' || !this.trusteeSixDetails || this.trusteeSixDetails === '') {
          isValid = true;
        }
      }
    }
    if (this.formModel.trusteeType === 2) {
      if (!this.companyDetails || this.companyDetails === '') {
        isValid = true;
      }
    }
    return isValid;
  }

  getEmail(email) {
    console.log(email);
  }

  getTrusteeDetails(trusteeKey, details) {
    const dialogRef = this._matDialog.open(TrusteeDetailsPopupComponent, {
      width: '900px',
      data: details
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.formModel[trusteeKey] = result;
        if (trusteeKey === 'trusteeOneDetails') {
          this.trusteeOneDetails = result;
          this.formGroup.controls.trusteeOneName.setValue(result.title + " " + result.firstName + " " + (result.middleName ? result.middleName : '') + " " + (result.surname ? result.surname : ''));
        } else if (trusteeKey === 'trusteeTwoDetails') {
          this.trusteeTwoDetails = result;
          this.formGroup.controls.trusteeTwoName.setValue(result.title + " " + result.firstName + " " + (result.middleName ? result.middleName : '') + " " + (result.surname ? result.surname : ''));
        } else if (trusteeKey === 'trusteeThreeDetails') {
          this.trusteeThreeDetails = result;
          this.formGroup.controls.trusteeThreeName.setValue(result.title + " " + result.firstName + " " + (result.middleName ? result.middleName : '') + " " + (result.surname ? result.surname : ''));
        } else if (trusteeKey === 'trusteeFourDetails') {
          this.trusteeFourDetails = result;
          this.formGroup.controls.trusteeFourName.setValue(result.title + " " + result.firstName + " " + (result.middleName ? result.middleName : '') + " " + (result.surname ? result.surname : ''));
        } else if (trusteeKey === 'trusteeFiveDetails') {
          this.trusteeFiveDetails = result;
          this.formGroup.controls.trusteeFiveName.setValue(result.title + " " + result.firstName + " " + (result.middleName ? result.middleName : '') + " " + (result.surname ? result.surname : ''));
        } else if (trusteeKey === 'trusteeSixDetails') {
          this.trusteeSixDetails = result;
          this.formGroup.controls.trusteeSixName.setValue(result.title + " " + result.firstName + " " + (result.middleName ? result.middleName : '') + " " + (result.surname ? result.surname : ''));
        }
      }
      console.log('The dialog was closed');
    });
  }

  //mobile number validation
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSelectionHowManyPeople(people) {
    if (people === 1 && this.formGroup.controls.trusteeType.value === 1) {
      this.formGroup.controls.trusteeType.setValue(2);
      this.formModel.trusteeType = 2;
    } else if (people === 2 && this.formModel.trusteeType === 2) {
      this.formGroup.controls.trusteeType.setValue(1);
      this.formModel.trusteeType = 1;
    }

    if (people === 1) {
      this.formGroup.controls.trusteeOneName.setValue(null);
      this.formGroup.controls.trusteeTwoName.setValue(null);
      this.formGroup.controls.trusteeThreeName.setValue(null);
      this.formGroup.controls.trusteeFourName.setValue(null);
      this.formGroup.controls.trusteeFiveName.setValue(null);
      this.formGroup.controls.trusteeSixName.setValue(null);

      this.formModel.trusteeOneDetails = null;
      this.formModel.trusteeTwoDetails = null;
      this.formModel.trusteeThreeDetails = null;
      this.formModel.trusteeFourDetails = null;
      this.formModel.trusteeFiveDetails = null;
      this.formModel.trusteeSixDetails = null;

      this.trusteeOneDetails = null;
      this.trusteeTwoDetails = null;
      this.trusteeThreeDetails = null;
      this.trusteeFourDetails = null;
      this.trusteeFiveDetails = null;
      this.trusteeSixDetails = null;
    } else if (people === 2) {
      this.formGroup.controls.trusteeTwoName.setValue(null);
      this.formGroup.controls.trusteeThreeName.setValue(null);
      this.formGroup.controls.trusteeFourName.setValue(null);

      this.formModel.trusteeTwoDetails = null;
      this.formModel.trusteeThreeDetails = null;
      this.formModel.trusteeFourDetails = null;
      this.formModel.trusteeFiveDetails = null;
      this.formModel.trusteeSixDetails = null;

      this.trusteeTwoDetails = null;
      this.trusteeThreeDetails = null;
      this.trusteeFourDetails = null;
      this.trusteeFiveDetails = null;
      this.trusteeSixDetails = null;
    } else if (people === 3) {
      this.formGroup.controls.trusteeThreeName.setValue(null);
      this.formGroup.controls.trusteeFourName.setValue(null);

      this.formModel.trusteeThreeDetails = null;
      this.formModel.trusteeFourDetails = null;
      this.formModel.trusteeFiveDetails = null;
      this.formModel.trusteeSixDetails = null;

      this.trusteeThreeDetails = null;
      this.trusteeFourDetails = null;
      this.trusteeFiveDetails = null;
      this.trusteeSixDetails = null;
    } else if (people === 4) {
      this.formGroup.controls.trusteeFourName.setValue(null);
      this.formGroup.controls.trusteeFiveName.setValue(null);
      this.formGroup.controls.trusteeSixName.setValue(null);

      this.formModel.trusteeFourDetails = null;
      this.formModel.trusteeFiveDetails = null;
      this.formModel.trusteeSixDetails = null;

      this.trusteeFourDetails = null;
      this.trusteeFiveDetails = null;
      this.trusteeSixDetails = null;
    }else if (people === 5) {
      this.formGroup.controls.trusteeFiveName.setValue(null);
      this.formGroup.controls.trusteeSixName.setValue(null);

      this.formModel.trusteeFiveDetails = null;
      this.formModel.trusteeSixDetails = null;

      this.trusteeFiveDetails = null;
      this.trusteeSixDetails = null;
    }else if (people === 6) {
      this.formGroup.controls.trusteeSixName.setValue(null);

      this.formModel.trusteeSixDetails = null;

      this.trusteeSixDetails = null;
    }

  }

  onSelectionTrusteeType(type) {
    if (type === 1 && this.formGroup.controls.numberOfPeople.value === 1) {
      this.formGroup.controls.numberOfPeople.setValue(2);
      this.formModel.numberOfPeople = 2;
    }
    if (type === 2 && this.formGroup.controls.numberOfPeople.value === 2) {
      const dialogRef = this._matDialog.open(ErrorPopupComponent, {
        width: '800px'
      });
    }
  }

  getCompanyDetails() {
    const dialogRef = this._matDialog.open(CompanyDetailsPopupComponent, {
      width: '800px',
      data: this.companyDetails
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companyDetails = result;
        this.formGroup.controls.companyName.setValue(result.preferredCompanyName ? result.preferredCompanyName : "");
      }
    });
  }

  handleSuccess(e) {
    console.log("ReCaptcha", e);
  }
}
