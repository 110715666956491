<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Blogs</h1>
            <p>News and Insights</p>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let element of pagination.data">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <a [routerLink]="'/blog-details/' + element.url"><img
                                    [src]="_utilityService.getImageUrl(element.image)" [alt]="element.title"></a>
                            <div class="date"><i class='bx bx-calendar'></i> {{element.createdAt | date : "MMM, dd
                                yyyy"}}
                            </div>
                        </div>
                        <div class="post-content">
                            <h3><a [routerLink]="'/blog-details/' + element.url">{{element.title}}</a></h3>
                            <div class="post-info">
                                <div class="post-by">
                                    <!-- <img src="assets/img/author-image/img1.jpg" alt="image"> -->
                                    <h6>{{element.author}}</h6>
                                </div>
                                <div class="details-btn">
                                    <a [routerLink]="'/blog-details/' + element.url"><i
                                            class="bx bx-right-arrow-alt"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="col-lg-12 col-md-12">
                <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)"
                    [length]="pagination.totalCount" [pageSize]="pagination.perPage" [disabled]="disabled"
                    [showFirstLastButtons]="showFirstLastButtons"
                    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                    [pageIndex]="pagination.currentPage - 1" aria-label="Select page">
                </mat-paginator>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>