import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment.prod';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss']
})
export class FeesComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: MetaService
  ) {
    this.metaService.createCanonicalURL();
    this.titleService.setTitle(`Fees | ${environment.projectName}`);
  }

  ngOnInit(): void {
  }

}
