<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JEBTYR4FFH"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-JEBTYR4FFH');
</script>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>EASY <span class="logo-one-digit">1</span> SUPER -SETUP</h1>
            <p>SETTING UP PROCESS</p>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image" style="text-align: center;">
                        <img src="assets/img/setup-process.png" alt="image">
                    </div>
                    <div class="article-content">
                        <h3 style="color: red;"><i class="bx bxs-badge-check"></i> WHAT IS THE PROCESS TO SETUP?</h3>
                        <p>If you have taken a decision to set up a self-managed superfund - SMSF , <span
                                class="logo-one-digit">1</span> superfund can
                            assist you to establish an SMSF . </p>
                        <p><span class="logo-one-digit">1</span> superfund do not advice or recommend whether setting up
                            a superfund is right or beneficial
                            for you in your circumstances. Trustees need to make their own research whether it is right
                            for them and if required contact a licensed financial planner. However once decided by
                            trustees, <span class="logo-one-digit">1</span> superfund can set up the superfund which is
                            of clerical and technical nature
                            with standard off the shelf trust deed and help you with the accounting, tax return and
                            audit compliance of your superfund. We do not advice where you can invest your fund or no
                            invest your fund, trustees need to do their own research or contact a licensed financial
                            planner if they need assistance.</p>
                        <div style="text-align: center;">
                            <a routerLink="/apply-online" class="default-btn"><i class="bx bxs-hot"></i> Simply Apply
                                Online<span></span></a> &nbsp;
                            <p>Or Just let us know that you are interested to setup an SMSF </p>
                            <p>via email <a href="mailto:service@1superfund.com.au"
                                    style="color: red;">service@1superfund.com.au</a> </p>
                        </div>
                        <p>Your dedicated client manager will contact you and guide you step by step process.</p>

                        <h3 class="text-color"><i class="bx bxs-badge-check"></i> Things to consider when setting up
                            SMSF</h3>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Consider You are ready to take control of your own
                                super</li>
                        </h3>
                        <p>You are ready to take control of your own super, as it’s a big responsibility . Deciding on
                            an SMSF is a big decision – not to be taken lightly. Weigh up the pros and cons to see if
                            it’s right for you.</p>
                        <p>Consider appointing professionals to help you</p>
                        <p>There are few types of smsf professionals – you need to considers which one is best for you .
                        </p>
                        <p>Financial Planners - Those who need one to one advice and investment advice – they can choose
                            a licensed financial planners based on our research who sometime charges a high fees ( again
                            based on need may be this option may be right for some user)</p>
                        <p>Restrictive investment model – There are some providers who often setup smsf and smsf
                            compliance for free – but user is restricted to use their platform and buy other financial
                            products from them, from which they might get hefty commission.</p>
                        <p><span class="logo-one-digit">1</span> superfund – At <span class="logo-one-digit">1</span>
                            superfund SMSF are not restricted to use any specific investment platform,
                            SMSF have true freedom in the way invest their asset as permitted by SIS act. It fees start
                            from very low setup fees with very low yearly compliance fees offering great long-term
                            savings.
                        </p>
                        <h3 class="text-color">
                            <li>
                                <i class='bx bxs-badge-check'></i> Choose individual trustees or a corporate trustee
                            </li>
                        </h3>
                        <p>The SMSF need at least two trustees. If you are alone in the SMSF and do not want to bring
                            some one from outside of the smsf to hand share control of your smsf you should choose
                            corporate trustee. </p>
                        <p>Other benefits of a corporate SMSF trustee structure include:</p>
                        <br>
                        <li class="normal-li"><i class='bx bx-check-double'></i> it can be easy and more cost effective
                            to add or remove members</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> legal ownership of assets does not
                            change when a director/member is added or removedI</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> it’s the only option if you want to
                            manage your SMSF by yourself</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> there may be fewer problems for
                            succession planning where you have two members and one
                            passes away, as the remaining member can stay as the sole director of the trustee company
                        </li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> Companies have limited liability, so a
                            corporate trustee offers greater protection if the
                            trustee is sued for damages.</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> Australian Tax Office (ATO)
                            administrative penalties apply to the corporate trustee, not
                            each individual director.</li>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Appoint your trustees or directors</li>
                        </h3>
                        <p>New funds usually appoint trustees or directors under the fund’s trust deed.</p>
                        <p>You need to ensure that the people who become trustees or directors of the SMSF:</p>
                        <li class="normal-li"><i class='bx bx-check-double'></i> are eligible to be a trustee or
                            director</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> understand what it means to be a
                            trustee or director.</li>
                        <p>All trustees and directors must:</p>
                        <li class="normal-li"><i class='bx bx-check-double'></i> consent in writing to their appointment
                        </li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> sign the Trustee declaration stating
                            they understand their responsibilities (this must be
                            done within 21 days of becoming a trustee or director).</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> You must keep these documents on file
                            for the life of the SMSF and for 10 years after the
                            SMSF winds up.</li>
                        <p><span class="logo-one-digit">1</span> superfund can help you prepare these documents, and our
                            FREE document storage means copy of
                            these documents are secure in our vault while you keep the original copy also – as per your
                            obligation as trustee.</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Create the trust and trust deed</li>
                        </h3>
                        <p>A trust deed is a legal document that sets out the rules for establishing and operating your
                            fund. It includes such things as the fund’s objectives, who can be a member and whether
                            benefits can be paid as a lump sum or income stream. The trust deed and super laws together
                            form the fund’s governing rules.</p>
                        <p>The trust deed must be:</p>
                        <li class="normal-li"><i class='bx bx-check-double'></i> prepared by someone competent to do so
                            as it's a legal document</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> signed and dated by all trustees</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> properly executed according to state or
                            territory laws</li>
                        <li class="normal-li"><i class='bx bx-check-double'></i> regularly reviewed, and updated as
                            necessary.</li>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Register your fund and get an ABN</li>
                        </h3>
                        <p>Your SMSF must register through the Australian Business Register – and you should elect to be
                            regulated by the ATO.</p>
                        <p>After the registration, your SMSF will be listed on Super Fund Lookup. This will allow other
                            funds and your employer(s) to check your fund’s eligibility to receive rollovers and
                            contributions.</p>
                        <p>ATO will post you Confirmation that your SMSF is established with the ATO. ATO will also post
                            the funds TFN & ABN</p>
                        <p><span class="logo-one-digit">1</span> superfund expert technical team can register the fund
                            and get an ABN for the SMSF (If
                            required)</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Set up a bank account</li>
                        </h3>
                        <p>You need to open a bank account in your fund’s name to manage the fund’s operations and
                            accept contributions, rollovers of super and income from investments. This account is used
                            to pay the fund’s expenses and liabilities.</p>
                        <p>The fund’s bank account must be kept separate from the trustees’ individual bank accounts and
                            any related employers’ bank accounts.</p>
                        <p>ou can contact any financial institution of your choice and open a bank account. There are
                            paid and free options, we recommend you select free option as it will allow you further
                            savings. </p>
                        <p>As <span class="logo-one-digit">1</span> superfund is independent provider, we do not
                            recommend any specific financial
                            institution and do not obtain any commission from any financial institution.</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Get an electronic service address</li>
                        </h3>
                        <p>You may need an electronic service address (ESA) , <span class="logo-one-digit">1</span>
                            superfund expert technical team can
                            arrange one for you ( If required)</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Transferring your existing superannuation into your
                                SMSF</li>
                        </h3>
                        <p>When your SMSF is set up, you can request your retail Super Fund to transfer or rollover your
                            Superannuation balance over to your SMSF. </p>
                        <p>It’s fairly easy process, you can just phone your industry superfund and ask for the “Benefit
                            Rollover Request” form and the process of getting the funds transferred. They will guide you
                            step by step. </p>
                        <p>Once decided by you, <span class="logo-one-digit">1</span> superfund expert technical team
                            can arrange the rollover for you (If
                            required) </p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Start investing </li>
                        </h3>
                        <p>After the rollover of fund, this will then centralise your assets in one place and allow you
                            to use them to carry out your fund’s investment strategy – whatever that is., <span
                                class="logo-one-digit">1</span> superfund do
                            not restrict you on any particular investment. Just you need to be careful the investments
                            are allowed as per SIS Act and as per your documented investment strategy, as <span
                                class="logo-one-digit">1</span> superfund do
                            not advice on the investment side, you need to do your own research or if not sure contact a
                            licensed financial planner.</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Prepare an exit strategy</li>
                        </h3>
                        <p>It may sound strange to prepare an exit strategy at the beginning – but this will avoid any
                            confusion. What will happen if and when the fund winds up and how will members be paid?</p>
                        <p><span class="logo-one-digit">1</span> superfund trust deed incorporate this in the trust
                            deed, so you can sit relax and no need
                            incur additional cost each year (unless your objective charges)</p>
                        <h3 class="text-color">
                            <li><i class='bx bxs-badge-check'></i> Disclaimer</li>
                        </h3>
                        <p>As self-managed superfund is for those who have sufficient knowledge about running SMSF and
                            have clear understanding on the investment path you want to choose. </p>
                        <p>After 1 super setup an SMSF and asked ATO for registration, in random cases ATO may choose to
                            interview the trustees and make their decision after they have a chat with the trustees and
                            satisfied that trustees have required knowledge to be able to run their own smsf.</p>
                        <h3 style="color: red; text-transform: uppercase;font-weight: bold;">CAN <span
                                class="logo-one-digit">1</span> SUPERFUND TAKE CARE
                            ALL THIS FOR ME?</h3>
                        <p><span class="logo-one-digit">1</span> superfund expert team will take care of these for you
                            while you sit and relax. </p>
                        <div style="text-align: center;">
                            <a routerLink="/apply-online" class="default-btn"><i class="bx bxs-hot"></i> Simply Apply
                                Online<span></span></a> &nbsp;
                        </div>
                        <h3 style="color: red; text-transform: uppercase;font-weight: bold;">FEELING A BIT LAZY! – CAN A
                            <span class="logo-one-digit">1</span> SUPER CLIENT MANAGER FILL THE FORM FOR ME.
                        </h3>
                        <p>Sure, would be a pleasure, just send us an email to <a
                                href="mailto:service@1superfund.com.au">service@1superfund.com.au</a> , I dedicated
                            concierge client manger will contact you and guide you step by step on the setup process.
                        </p>
                        <p>Ha! Just have to promise us you won’t be lazy when keeping an eye on your investment and
                            meeting the annual compliance of your smsf, after all its your money and a responsibility
                            for your retirement.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>