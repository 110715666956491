import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from '../../services/constant.service';

@Component({
  selector: 'app-trustee-details-popup',
  templateUrl: './trustee-details-popup.component.html',
  styleUrls: ['./trustee-details-popup.component.scss']
})
export class TrusteeDetailsPopupComponent implements OnInit {

  myFilter = (d: Date | null): boolean => {
    const calendarDate = (d || new Date());
    const currentDate = new Date();
    let diff = this.monthDiff(calendarDate, currentDate);
    return diff > 216;
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  formModel: any = {
    title: null,
    firstName: null,
    middleName: null,
    surname: null,
    taxFileNumber: null,
    dateOfBirth: null,
    email: null,
    mobileNumber: null,
    address: {
      residentialAddress: {
        address: null,
        suburb: null,
        state: null,
        postCode: null
      },
      postalAddress: {
        address: null,
        suburb: null,
        state: null,
        postCode: null
      }
    },
    countryOfCitizenship: null,
    jobCategory: null,
    jobType: null,
    isTrusteeAnUndischargedBankrupt: null,
    hasTrusteeBeenConvictedOfDishonestConduct: null,
    hasTheTrusteeReceivedACivilPenaltyOrder: null,
    hasTrusteeBeenDisqualifiedByTheATOOrAPRA: null,
    isTheTrusteeAResidentOfAustralia: null
  }

  constructor(
    public matDialogRef: MatDialogRef<TrusteeDetailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _constantService: ConstantService
  ) {
    matDialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this._data) {
      this.formModel = this._data;
    }
  }

  isValid() {
    if (
      !this.formModel.title || this.formModel.title === ''
      || !this.formModel.firstName || this.formModel.firstName === ''
      || !this.formModel.taxFileNumber || this.formModel.taxFileNumber === ''
      || !this.formModel.email || this.formModel.email === ''
      || !this.formModel.mobileNumber || this.formModel.mobileNumber === ''
      || !this.formModel.address.residentialAddress.address || this.formModel.address.residentialAddress.address === ''
      || !this.formModel.address.residentialAddress.suburb || this.formModel.address.residentialAddress.suburb === ''
      || !this.formModel.address.residentialAddress.state || this.formModel.address.residentialAddress.state === ''
      || !this.formModel.address.residentialAddress.postCode || this.formModel.address.residentialAddress.postCode === ''
      || !this.formModel.address.postalAddress.address || this.formModel.address.postalAddress.address === ''
      || !this.formModel.address.postalAddress.suburb || this.formModel.address.postalAddress.suburb === ''
      || !this.formModel.address.postalAddress.state || this.formModel.address.postalAddress.state === ''
      || !this.formModel.address.postalAddress.postCode || this.formModel.address.postalAddress.postCode === ''
      // || !this.formModel.jobCategory || this.formModel.jobCategory === ''
      // || !this.formModel.jobType || this.formModel.jobType === ''
      || !this.formModel.isTrusteeAnUndischargedBankrupt || this.formModel.isTrusteeAnUndischargedBankrupt === ''
      || !this.formModel.hasTrusteeBeenConvictedOfDishonestConduct || this.formModel.hasTrusteeBeenConvictedOfDishonestConduct === ''
      || !this.formModel.hasTheTrusteeReceivedACivilPenaltyOrder || this.formModel.hasTheTrusteeReceivedACivilPenaltyOrder === ''
      || !this.formModel.hasTrusteeBeenDisqualifiedByTheATOOrAPRA || this.formModel.hasTrusteeBeenDisqualifiedByTheATOOrAPRA === ''
      || !this.formModel.isTheTrusteeAResidentOfAustralia || this.formModel.isTheTrusteeAResidentOfAustralia === ''
    ) {
      return true;
    }
    return false;
  }

  submit() {
    this.matDialogRef.close(this.formModel);
  }

  //mobile number validation
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
