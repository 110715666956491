import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../../services/blog.service';
import { UtilityService } from '../../services/utility.service';
import { FormGroup } from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { MetaService } from '../../services/meta.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  url: any;
  dataObject: any;
  commentFormGroup!: FormGroup;
  commentFormData: any;
  isSaveData: boolean = false;
  comments: any[] = [];
  categories: any[] = [];
  popularBlogs: any[] = [];
  environment: any = environment;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _blogService: BlogService,
    public _utilityService: UtilityService,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private metaService: MetaService
    ) {
        this.metaService.createCanonicalURL();
    if (localStorage.getItem("commentUser")) {
      this.commentFormData = JSON.parse(localStorage.getItem("commentUser"));
      this.commentFormData['isSaveDataToBrowser'] = true;
    }
    this._activatedRoute.params.subscribe(routeParams => {
      this.url = routeParams.url;
      this.blogDetails();
    });
    this.commentFormGroup = _blogService.createCommentForm(this.commentFormData);
  }

  blogDetails() {
    this._blogService.getDataByUrl(this.url).then((response: any) => {
      this.dataObject = response.data.blog;
      this.dataObject.description = this.dataObject.description.replace("\"", "");
      this.dataObject.description = this.sanitizer.bypassSecurityTrustHtml(this.dataObject.description);
      this.comments = response.data.comments;
      this.titleService.setTitle(`Blogs | ${this.dataObject.title} | ${environment.projectName}`);
    })
  }

  ngOnInit() {

    this._blogService.getCategoryList().then((response: any) => {
      this.categories = response.data;
    })

    this._blogService.getPopularBlogs().then((response: any) => {
      this.popularBlogs = response.data;
    })
  }

  submit() {
    const data = this.commentFormGroup.getRawValue();
    data.blogId = this.dataObject._id;
    this.isSaveData = data.isSaveDataToBrowser;
    if (data.isSaveDataToBrowser)
      localStorage.setItem("commentUser", JSON.stringify({ name: data.name, email: data.email, website: data.website }));
    delete data.isSaveDataToBrowser;
    this._blogService.addComment(data).then((response: any) => {
      if (response && response.status === 'OK') {
        if (!this.isSaveData) {
          this.commentFormGroup.reset();
          this.commentFormGroup = this._blogService.createCommentForm(null);
        }
        else
          this.commentFormGroup.controls.comment.setValue(null);
      }
      this.blogDetails();
    })
  }

}
